import React from "react";
import { Box, Flex, IconButton, Text, VStack } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";

import routes from "routes.js";


const BottomNavigation = () => {
  const visibleRoutes = routes.filter((route) => !route.nolink);
  console.log(visibleRoutes);
  let location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

    

  return (
    <Box
      zIndex="5"
      position="fixed"
      bottom="0"
      width="100%"
      display={{ base: "flex", md: "none" }} // Show only on mobile (base is mobile size)
      backgroundColor="white"
      color="white"
      py={2}
      boxShadow="0px -2px 10px rgba(0, 0, 0, 0.1)"
    >
      <Flex
        justifyContent="space-around"
        alignItems="center"
        width="100%"
        px="10px"
      >
        {visibleRoutes.map((route, index) => {
          console.log(route.name);
          return !route.desktopOnly ? (
            <NavLink key={index} to={route.layout + route.path}>
              <VStack spacing={1}>
                <route.mobile_icon
                  size="32"
                  color="#203764"
                  variant={activeRoute(route.path.toLowerCase()) ? "Bold" : "Outline"}
                />

                <Text color="#203764"> {route.name}</Text>
              </VStack>
            </NavLink>
          ) : null;
        })}
        {/* <VStack spacing={1}>
          <IconButton
            icon={<FaHome size="28px" color="#203764" />}
            aria-label="Home"
            variant="ghost"
            colorScheme="#203764"
            mb="-5px"
          />
          <Text color="#203764"> Home</Text>
        </VStack> */}
      </Flex>
    </Box>
  );
};

export default BottomNavigation;
