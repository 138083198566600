import * as Yup from "yup";

export const inviteExpertSchema = Yup.object().shape({
  emails: Yup.array().of(
    Yup.string().email("Invalid email").required("Email is required")
  ),
});


export const inviteExpertInitialValues = { emails: [""] }

export const expertValidationSchema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  phone: Yup.string()
    .matches(/^[0-9]*$/, "Phone number can only contain numbers")
    .length(10, "Phone number must be exactly 10 digits")
    .required("Phone number is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
});

export const editExpertInitialValues = {
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
};
