/* eslint-disable */
import React, { useState, useRef } from "react";

import {
  Box,
  Flex,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  InputGroup,
  Text,
  useColorModeValue,
  Heading,
  HStack,
  FormControl,
  InputRightElement,
} from "@chakra-ui/react";
import CButton from "components/fields/Button";
import { useAuth } from "contexts/AppContext";
import { ArrowLeft2, Eye, EyeSlash, LogoutCurve, Trash } from "iconsax-react";
import { useNavigate } from "react-router-dom";

function ChangeEmail() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { colorMode } = useColorMode();

  const [error, setError] = useState("");
  const inputFileRef = useRef(null);

  const { login } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleShowPass = () => setShow(!show);

  const handleLogin = async (e) => {
    setIsDisabled(true);
    e.preventDefault();
    const success = await login(username, password);
    if (!success) {
      setIsDisabled(false);
      setError("Invalid username or password");
    }
  };

  const navigate = useNavigate();

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        px={{ base: "16px", md: 0 }}
        backgroundColor={{ base: "#fff", md: "transparent" }}
        h="48px"
      >
        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="500"
          display="flex"
          alignItems="center"
          gap={1}
          cursor="pointer"
          onClick={() => navigate("/admin/profile/edit-profile")}
          color="primary.primary"
        >
          <ArrowLeft2 fontSize={24} />
          Change Email
        </Heading>
      </Flex>
      <Flex
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "0px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "558px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box>
            Write your new email for the verification process. We will send a 4
            digit code to your email.
          </Box>
          <form onSubmit={handleLogin}>
            <FormControl isInvalid={error ? true : false}>
              <FormLabel
                display="flex"
                ms="4px"
                mt="24px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="username"
              >
                New Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="ex: myname@example.com"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                borderColor={error ? errorMessage : null}
                value={username}
                id="username"
                name="username"
                onChange={(e) => setUsername(e.target.value)}
              />
              {!error ? null : (
                <FormErrorMessage mt="-18px" ml="10px">
                  {error}
                </FormErrorMessage>
              )}
              <FormLabel
                mt="30px"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                htmlFor="password"
              >
                Current Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="********"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  borderRadius="10px"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                />
                <InputRightElement
                  display="flex"
                  alignItems="center"
                  mt="4px"
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  onClick={handleShowPass}
                >
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? Eye : EyeSlash}
                    onClick={handleShowPass}
                  />
                </InputRightElement>
              </InputGroup>
              <HStack>
                <CButton
                  variant="outline"
                  onClick={() => navigate("/admin/profile/edit-profile")}
                >
                  Cancel
                </CButton>
                <CButton
                  fontSize="base"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  type="submit"
                  bgColor="#203764"
                  textColor="#fff"
                >
                  Continue
                </CButton>
              </HStack>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </Box>
  );
}

export default ChangeEmail;
