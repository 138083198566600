import { Button, Flex, Box, useColorModeValue } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";

import React from "react";
import { Notification, Setting2, LogoutCurve } from "iconsax-react";

export default function SidebarDocs({ isSidebarExpanded }) {
  const brandColor = useColorModeValue("brand.500", "white");
  let location = useLocation();

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  return (
    <Flex
      justify="center"
      direction={isSidebarExpanded ? "row" : "column"}
      align="center"
      borderRadius="30px"
      position="relative"
      px="40px"
      width="70%"
    >
      <NavLink key={1} to="/admin/profile">
        <Box
          bgColor={activeRoute("profile") ? "#C5D2EC" : ""}
          height="60px"
          width="60px"
          borderRadius="15px"
          mx="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Setting2 color="#203764" />
        </Box>
      </NavLink>

      <NavLink key={2} to="/admin/notification">
        <Box
          bgColor={activeRoute("notification") ? "#C5D2EC" : ""}
          height="60px"
          width="60px"
          borderRadius="15px"
          mx="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Notification color="#203764" />
        </Box>
      </NavLink>
      <NavLink key={3} to="/auth/sign-out">
        <Box
          bgColor={activeRoute("sign-out") ? "#C5D2EC" : ""}
          height="60px"
          width="60px"
          borderRadius="15px"
          mx="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <LogoutCurve color="#203764" />
        </Box>
      </NavLink>
    </Flex>
  );
}
