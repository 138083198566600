import api from '../apiService';
import { useMutation } from '@tanstack/react-query';
import { API_URLS } from 'config/apiConfig';

const inviteExperts = async (body) => {
  const response = await api.post(API_URLS.invite_expert, body);

  return response;
};

export default function useInviteExperts() {
  return useMutation({
    mutationFn: inviteExperts,
  });
}

