import api from '../apiService';
import { useMutation } from '@tanstack/react-query';
import { API_URLS } from 'config/apiConfig';

const editExpert = async (payload) => { 
  const {id, ...body} = payload || {}
  const response = await api.put(`${API_URLS.expert}/${id}`, body);

  return response;
};

export default function useEditExpert() {
  return useMutation({
    mutationFn: editExpert,
  });
}

