import React from "react";
import {
    Box,
    Card,
    Flex,
    Text,
    Icon,
    Button,
    useColorModeValue,
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Skeleton,
    Badge,
} from "@chakra-ui/react";
import { CloseCircle, ArrowRight2, ImportCurve } from "iconsax-react";

const invoiceTableHeaders = [
    { text: "Date" },
    { text: "Amount" },
    { text: "Status" },
    { text: "Plan Name" },
];
const isLoading = false
const projects = []
const invoice = [{ date: 'Oct 26, 2024', amount: '$34', status: 'paid', planName: 'Repolet Monthly' }]

function InvoiceHistory() {
    const textColor = useColorModeValue("navy.700", "white");
    const handleDetails = (item) => {
        console.log(item);
    }

    return (
        <Card px={6} py={8} width={'100%'}>
            <Flex justifyContent={'space-between'} mb={4} alignItems={'center'}>
                <Text fontSize={'2xl'} color={'primary.primary'} fontWeight={'medium'}>Invoice History</Text>
                <Text fontSize={'md'} color={'primary.primary'} fontWeight={'medium'}>I need help with my subscription</Text>
            </Flex>
            <Flex justifyContent={'flex-start'} gap={3} mt={4} alignItems={'center'}>
                <Button variant="outline" size={'sm'} color={'info.info'} borderRadius={'none'} borderColor='info.info' >Last 3 Months</Button>
                <Button variant="outline" size={'sm'} color={'info.info'} borderRadius={'none'} borderColor='info.info' >Last 6 Months</Button>
                <Button variant="solid" size={'sm'} color={'info.info'} borderRadius={'none'} backgroundColor={'info.onInfo'} rightIcon={<CloseCircle />}>Last Year</Button>
            </Flex>
            <Box
                overflowX="auto"
                display={{ base: "none", md: "block" }}
                mt={8}
                p={0}
                borderRadius={15}
                backgroundColor="white"
                boxShadow="sm"
            >
                <TableContainer>
                    <Table variant="simple" colorScheme="brand" size="sm">
                        <Thead h={16}>
                            <Tr>
                                {invoiceTableHeaders.map((item, index) => (
                                    <Th
                                        bg="#EBEFFF"
                                        textColor={textColor}
                                        textTransform={"none"}
                                        fontSize="sm"
                                        fontWeight="500"
                                    >
                                        <Flex alignItems="center">
                                            <Text>{item.text}</Text>
                                        </Flex>
                                    </Th>
                                ))}
                                <Th
                                    bg="#EBEFFF"
                                    textColor={textColor}
                                    textTransform={"none"}
                                    fontSize="sm"
                                    fontWeight="500"
                                ></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {isLoading ? (
                                Array.from({ length: 4 }).map((_, index) => (
                                    <Tr key={index} h={12}>
                                        <Td>
                                            <Skeleton height="20px" />
                                        </Td>
                                        <Td>
                                            <Skeleton height="20px" />
                                        </Td>
                                        <Td>
                                            <Skeleton height="20px" />
                                        </Td>
                                        <Td>
                                            <Skeleton height="20px" />
                                        </Td>
                                    </Tr>
                                ))
                            ) : invoice.length > 0 ? (
                                invoice.map((item) => (
                                    <Tr
                                        h={16}
                                        key={item.id}
                                        _hover={{ bg: "#f7f7f7" }}
                                        sx={{ borderBottom: "1px solid black" }}
                                        onClick={() => handleDetails(item)}
                                        cursor="pointer"
                                    >
                                        <Td>{item.date || ""}</Td>
                                        <Td>{item.amount || ""}</Td>
                                        <Td>{item.status === 'paid' && (
                                            <Badge variant='subtle' color={'success.success'} borderRadius={'4px'} backgroundColor={'info.tritary'} fontSize={'xs'} textTransform="none">
                                                Paid
                                            </Badge>
                                        )}</Td>
                                        <Td>{item.planName || ""}</Td>
                                        <Td> <Button
                                            variant="link"
                                            onClick={() => handleDetails(item)}
                                            href="#"
                                        >
                                            <Flex alignItems="end">
                                                <Icon as={ImportCurve} w="24px" h="24px" ml={1} />
                                            </Flex>
                                        </Button></Td>
                                    </Tr>
                                ))
                            ) : (
                                <Tr>
                                    <Td colSpan={5} textAlign="center" py={10}>
                                        No Invoice Is Added
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            <Box display={{ base: "block", md: "none" }}>
                <Stack spacing={4}>
                    {isLoading ? (
                        <Box
                            borderRadius="lg"
                            overflow="hidden"
                            p={6}
                            bg="white"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Spinner size="lg" color={textColor} />
                        </Box>
                    ) : invoice.length > 0 ? (
                        invoice.map((item) => (
                            <Box
                                key={item.id}
                                borderRadius="lg"
                                overflow="hidden"
                                p={4}
                                bg="white"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                onClick={() => handleDetails(item)}
                            >

                                <Flex alignItems="center">
                                    <Text>
                                        {item.planName || "-"}
                                    </Text>
                                </Flex>
                                <Flex alignItems="center">
                                    <Text>
                                        {item.amount || "-"}
                                    </Text>
                                </Flex>
                                <Button
                                    key={item.id}
                                    variant="link"
                                    onClick={() => handleDetails(item)}
                                    href="#"
                                >
                                    <Flex alignItems="center">
                                        <Icon as={ArrowRight2} w="16px" h="16px" ml={1} />
                                    </Flex>
                                </Button>
                            </Box>
                        ))
                    ) : (
                        <Box
                            borderRadius="lg"
                            overflow="hidden"
                            p={6}
                            bg="white"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            No invoice Is Added
                        </Box>
                    )}
                </Stack>
            </Box>
        </Card>
    );
}

export default InvoiceHistory;
