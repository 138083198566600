import api from "../apiService";
import { useMutation } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

const addPaymentMethod = async (body) => {
  const response = await api.post(API_URLS.payment_method, body);

  return response;
};

export default function useAddPaymentMethod() {
  return useMutation({
    mutationFn: addPaymentMethod,
  });
}
