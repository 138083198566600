import api from "../apiService";
import { useMutation } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

const editCustomer = async (payload) => {
  const { id, ...body } = payload || {};
  const response = await api.put(`${API_URLS.all_customers}/${id}`, body);

  return response;
};

export default function useEditCustomer() {
  return useMutation({
    mutationFn: editCustomer,
  });
}
