import React from "react";
import { Flex, Text, useTheme, Box,Icon } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

// Custom components
import DefaultAuth from "layouts/auth/Default";
import PlanCard from "components/payment/PlanCard";

// Assets
import illustration from "assets/img/auth/auth.png";
import { ArrowLeft2 } from "iconsax-react";

function Plans() {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleBackButton = () => {
    window.history.back();
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        ml={{ base: "20px", lg: "50px" }}
        mt={{ base: "0px", lg: "20px" }}
        style={{ alignItems: "flex-end" }}
        onClick={handleBackButton}
        cursor={"pointer"}
        zIndex={10}
        h="48px"
        color="primary.primary"
      >
        <Box>
          <Icon
            as={ArrowLeft2}
            w="20px"
            h="20px"
            color="#000"
            marginRight={5}
          />
        </Box>

        <Text fontWeight={500} fontSize={22}>
          Subscription
        </Text>
      </Flex>

      <Flex
        direction="column"
        align="center"
        justifyContent="center"
        maxWidth={"800px"}
        mx={"auto"}
        py={{ base: "20px", md: "40px" }}
        px={{ base: "15px", md: "0" }}
        pb={{ base: "100px" }}
      >
        <Text
          fontSize={{ base: "2xl", md: "3xl" }}
          fontWeight="bold"
          mb="10px"
          color={theme.colors.brand["500"]}
          textAlign={"center"}
        >
          Instantly Generate Accurate Inspection Reports
        </Text>
        <Text
          fontSize={{ base: "md", md: "2xl" }}
          mb={{ base: "20px", md: "40px" }}
          w={{ base: "90%", md: "70%" }}
          textAlign={"center"}
          color={theme.colors.text.paragraph}
        >
          Transform your inspection data into Compliant reports that meet SB326
          and SB721 regulations.
        </Text>
        <Flex gap="36px" flexWrap="wrap" justify="center" w="100%">
          <PlanCard
            title="YEARLY"
            price="39.99"
            perEee="25"
            discount="20% Discount"
            onClick={() =>
              navigate("/onboarding/payment_details", {
                state: {
                  title: "YEARLY",
                  price: "39.99",
                  perEee: "25",
                  discount: "20% Discount",
                },
              })
            }
          />
          <PlanCard
            title="MONTHLY"
            price="49.99"
            perEee="20"
            onClick={() =>
              navigate("/onboarding/payment_details", {
                state: {
                  title: "MONTHLY",
                  price: "49.99",
                  perEee: "20",
                },
              })
            }
          />
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Plans;
