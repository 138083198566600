import api from '../apiService'
import { useQuery } from '@tanstack/react-query'
import { API_URLS } from 'config/apiConfig'

export const getExperts = async ({ page = 1, perPage = 10 }) => {
  const { data } = await api.get(API_URLS.exports)

  return data
}

export function useGetExperts({ enabled, page, perPage }) {
  return useQuery({
    queryKey: ['experts', page, perPage],
    enabled,
    queryFn: () => getExperts({ page, perPage }),
  })
}
