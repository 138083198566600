import { API_URLS } from "config/apiConfig";

// Onboarding a company (POST)
export const addCompany_service = async (
  name,
  phone_number,
  address,
  logo,
  description,
  token
) => {
  try {
    const formData = new FormData();
    formData.append("logo", logo);
    formData.append("name", name);
    formData.append("phone_number", phone_number);
    formData.append("address", address);
    formData.append("description", description);

    const response = await fetch(API_URLS.onboarding, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Onboarding failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Onboarding error:", error);
    throw error;
  }
};

// Invite expert (POST)
export const inviteExpert_service = async (emails, token) => {
  try {
    const _body = JSON.stringify({
      emails: emails,
    });

    const response = await fetch(API_URLS.invite_expert, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
      body: _body,
    });

    if (!response.ok) {
      throw new Error("Invite failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Invite expert error:", error);
    throw error;
  }
};

// Get list of company experts (GET)
export const getCompanyExperts_service = async (token) => {
  try {
    const response = await fetch(API_URLS.company_experts, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
    });

    if (!response.ok) {
      throw new Error("Fetch experts failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch experts error:", error);
    throw error;
  }
};

// Add project (POST)
export const addProject_service = async (
  project_address,
  project_name,
  expert_id,
  description,
  customer_id,
  first_name,
  last_name,
  phone,
  email,
  token
) => {
  try {
    const _body = JSON.stringify({
      name: project_name,
      address: project_address,
      expert_id: expert_id,
      description: description,
      customer_id: customer_id,
      customer: {
        id: customer_id,
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        email: email,
      },
    });

    const response = await fetch(API_URLS.add_projects, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
      body: _body,
    });

    if (!response.ok) {
      throw new Error("Add project failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Add project error:", error);
    throw error;
  }
};

// Edit project (PUT)
export const editProject_service = async (
  projectId,
  project_address,
  project_name,
  expert_id,
  description,
  customer_id,
  token
) => {
  try {
    const _body = JSON.stringify({
      name: project_name,
      address: project_address,
      expert_id: expert_id,
      description: description,
      customer_id: customer_id,
    });

    const response = await fetch(API_URLS.edit_projects + projectId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
      body: _body,
    });

    if (!response.ok) {
      throw new Error("Edit project failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Edit project error:", error);
    throw error;
  }
};

// Get list of company projects (GET)
export const getCompanyProjects_service = async (token) => {
  try {
    const response = await fetch(API_URLS.all_projects, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
    });

    if (!response.ok) {
      throw new Error("Fetch projects failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch projects error:", error);
    throw error;
  }
};

// Get list of company customers (GET)
export const getCompanyCustomers_service = async (token) => {
  try {
    const response = await fetch(API_URLS.all_customers, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
    });

    if (!response.ok) {
      throw new Error("Fetch customers failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch customers error:", error);
    throw error;
  }
};
