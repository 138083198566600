/* eslint-disable */
import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  Home,
  DocumentText,
  Profile2User,
  People,
  Setting2,
  Note,
} from "iconsax-react";

// Admin Imports
import MainDashboard from "views/admin/_default";
import HomeDashboard from "views/admin/home";
import Wizard from "views/admin/_wizard";
import NFTMarketplace from "views/admin/_marketplace/marketplace";
import Profile from "views/admin/company/profile";
import DataTables from "views/admin/_dataTables";
import Projects from "views/admin/projects";
import Experts from "views/admin/expert/experts";
import Customers from "views/admin/customer/customers";
import CustomerDetails from "views/admin/customer/customer_details";
import ProjectDetails from "views/admin/projects/project_details";
import Forms from "views/admin/forms/forms";

// Auth Imports
import SignOut from "views/auth/signout";
import SignInCentered from "views/auth/signIn";
import ForgetPassword from "views/auth/signIn/fortgetPassword";
import NewPassword from "views/auth/signIn/new_password";
import SignUp from "views/auth/signUp";
import GoogleSignInCallback from "views/auth/signIn/google_signin";
import GoogleSignUpCallback from "views/auth/signUp/google_signup";
import Accept_Invitee from "views/auth/signUp/signup_invitee";
import Otp from "views/auth/signUp/otp";
import AddCompany from "views/admin/company/add_company";
import NewProject from "views/admin/projects/new_project";
import EditProject from "views/admin/projects/edit_project";
import NewCustomer from "views/admin/customer/new_customer";
import EditCustomer from "views/admin/customer/edit_customer";
import StartInspection from "views/admin/company/start_inspection";
import InviteExpert from "views/admin/expert/invite_experts";
import ExpertDetails from "views/admin/expert/expert_details";
import EditExpert from "views/admin/expert/edit_expert";
import EditProfile from "views/admin/company/components/EditProfile";
import ChangeEmail from "views/admin/company/components/ChangeEmail";
import Plans from "views/admin/payment/plans";
import PaymentDetails from "views/admin/payment/payment_details";
import FormDetails from "views/admin/forms/FormDetails/FormDetails";
import AddForm from "views/admin/forms/AddForm/AddForm";
import SubscriptionSettings from "views/admin/company/subscription_settings";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "/home",
    icon: Home,
    mobile_icon: Home,
    component: <HomeDashboard />,
  },

  {
    name: "Projects",
    layout: "/admin",
    icon: DocumentText,
    mobile_icon: DocumentText,
    path: "/projects",

    component: <Projects />,
  },

  {
    name: "Customers",
    layout: "/admin",
    icon: People,
    mobile_icon: People,
    path: "/customers",
    component: <Customers />,
  },
  {
    name: "Experts",
    layout: "/admin",
    icon: Profile2User,
    mobile_icon: Profile2User,
    path: "/experts",
    component: <Experts />,
  },
  {
    name: "Forms",
    layout: "/admin",
    icon: Note,
    mobile_icon: Note,
    path: "/forms",
    component: <Forms />,
  },

  {
    name: "Wizard",
    layout: "/admin",
    path: "/wizard",

    nolink: true,
    component: <Wizard />,
  },
  {
    name: "Customer Details",
    layout: "/admin",
    path: "/customers/customer-details",
    nolink: true,
    component: <CustomerDetails />,
  },
  {
    name: "Expert Details",
    layout: "/admin",
    path: "/expert-details",
    nolink: true,
    component: <ExpertDetails />,
  },
  {
    name: "Projects Details",
    layout: "/admin",
    path: "/projects/project-details",
    nolink: true,
    component: <ProjectDetails />,
  },
  {
    name: "Form Details",
    layout: "/admin",
    path: "/forms/form-details",
    nolink: true,
    component: <FormDetails />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    nolink: true,
    path: "/sign-in",
    component: <SignInCentered />,
  },
  {
    name: "Forget Password",
    layout: "/auth",
    nolink: true,
    path: "/forget-password",
    component: <ForgetPassword />,
  },
  {
    name: "New Password",
    layout: "/auth",
    nolink: true,
    path: "/new_password",
    component: <NewPassword />,
  },
  {
    name: "Setting",
    layout: "/admin",
    icon: Setting2,
    mobile_icon: Setting2,
    path: "/profile",
    nolink: true,
    component: <Profile />,
  },
  {
    name: "Edit Profile",
    layout: "/admin",
    icon: Setting2,
    mobile_icon: Setting2,
    path: "/profile/edit-profile",
    nolink: true,
    component: <EditProfile />,
  },
  {
    name: "Change Email",
    layout: "/admin",
    icon: Setting2,
    mobile_icon: Setting2,
    path: "/profile/edit-profile/change-email",
    nolink: true,
    component: <ChangeEmail />,
  },
  {
    name: "[Sign Out]",
    layout: "/auth",
    path: "/sign-out",
    nolink: true,
    component: <SignOut />,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    nolink: true,
    component: <SignUp />,
  },
  {
    name: "Sign In With Google",
    layout: "/auth",
    path: "/sign-in/google",
    nolink: true,
    component: <GoogleSignInCallback />,
  },

  {
    name: "Sign Up Invitee",
    layout: "/auth",
    path: "/signup-invitee",

    nolink: true,
    component: <Accept_Invitee />,
  },
  {
    name: "OTP",
    layout: "/auth",
    path: "/otp",
    nolink: true,
    component: <Otp />,
  },
  {
    name: "Add Company",
    layout: "/onboarding",
    path: "/add-company",
    nolink: true,
    component: <AddCompany />,
  },
  {
    name: "New Project",
    layout: "/onboarding",
    path: "/new-project",

    nolink: true,
    component: <NewProject />,
  },
  {
    name: "Add Form",
    layout: "/admin",
    path: "/forms/add-form",
    nolink: true,
    component: <AddForm />,
  },
  {
    name: "Edit Project",
    layout: "/onboarding",
    path: "/edit-project",

    nolink: true,
    component: <EditProject />,
  },
  {
    name: "Edit Customer",
    layout: "/onboarding",
    path: "/edit-customer",

    nolink: true,
    component: <EditCustomer />,
  },
  {
    name: "Edit Expert",
    layout: "/onboarding",
    path: "/edit-expert",

    nolink: true,
    component: <EditExpert />,
  },
  {
    name: "New Customer",
    layout: "/onboarding",
    path: "/new-customer",

    nolink: true,
    component: <NewCustomer />,
  },
  {
    name: "Start Inspection",
    layout: "/onboarding",
    path: "/start-inspection",

    nolink: true,
    component: <StartInspection />,
  },
  {
    name: "Invite Expert Company",
    layout: "/onboarding",
    path: "/invite-experts",

    nolink: true,
    component: <InviteExpert />,
  },
  {
    name: "Subscription Plans",
    layout: "/onboarding",
    path: "/plans",

    nolink: true,
    component: <Plans />,
  },
  {
    name: "Payment Details",
    layout: "/onboarding",
    path: "/payment_details",

    nolink: true,
    component: <PaymentDetails />,
  },
  {
    name: "Subscription Settings",
    layout: "/onboarding",
    path: "/subscription_settings",

    nolink: true,
    component: <SubscriptionSettings />,
  },
  // {
  //   name: "Set 1",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Set 2",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: "/data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Set 3",
  //   layout: "/admin",
  //   path: "/profile2",
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: <Profile />,
  // },
];

export default routes;
