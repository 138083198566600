import { Box, Flex, Heading, useSteps } from "@chakra-ui/react";
import React from "react";
import FormStepper from "./components/FormStepper";
import { ArrowLeft2 } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import InspectionForm from "./components/TwoStepForm";

const steps = [
  { title: "Defining Name", description: "Form name" },
  { title: "Sections", description: "Add more sections" },
];

const AddForm = () => {
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  const navigate = useNavigate();

  return (
    <Box color="primary.primary">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        px={{ base: "16px", md: 0 }}
        backgroundColor={{ base: "#fff", md: "transparent" }}
        h="48px"
      >
        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="500"
          display="flex"
          alignItems="center"
          gap={1}
          cursor="pointer"
          onClick={() => navigate("/admin/forms")}
        >
          <ArrowLeft2 fontSize={24} />
          Add Form
        </Heading>
      </Flex>

      <Flex
        w={{ base: "100%", md: "800px" }}
        alignItems="center"
        justifyContent="center"
        mx="auto"
        direction="column"
        gap={6}
      >
        <FormStepper activeStep={activeStep} steps={steps} />
        <InspectionForm
          activeStep={activeStep}
          setActiveStep={(step) => setActiveStep(step)}
        />
      </Flex>
    </Box>
  );
};

export default AddForm;
