import React from "react";
import {
    Box,
    Card,
    Flex,
    Text,
    Icon,
    Button,
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
    Skeleton,
    Badge,
    Image,
} from "@chakra-ui/react";
import { Add, Edit } from "iconsax-react";

const isLoading = false
const cards = [{ cardNumber: 'Ziraat ********4568', isDefault: true, expireDate: '12/2027' }, { cardNumber: 'Ziraat ********4569', isDefault: false, expireDate: '12/2027' }]

function PaymentMethodInfo() {
    const handleDetail = (item)=>{
        console.log(item);
    }

    return (
        <Card px={6} py={8} width={'100%'}>
            <Text fontSize={'2xl'} color={'primary.primary'} fontWeight={'medium'} mb={4}>Payment Methods</Text>
            <Box
                overflowX="auto"
                mt={8}
                p={0}
                borderRadius={15}
                backgroundColor="white"
                boxShadow="sm"
            >
                <TableContainer>
                    <Table size="sm">
                        <Tbody>
                            {isLoading ? (
                                Array.from({ length: 4 }).map((_, index) => (
                                    <Tr key={index} h={12}>
                                        <Td>
                                            <Skeleton height="20px" />
                                        </Td>
                                        <Td display={{ base: "none", md: "table-cell" }}>
                                            <Skeleton height="20px" />
                                        </Td>
                                        <Td>
                                            <Skeleton height="20px" />
                                        </Td>
                                    </Tr>
                                ))
                            ) : cards.length > 0 ? (
                                cards.map((item, index) => (
                                    <Tr
                                        key={index}
                                        _hover={{ bg: "#f7f7f7" }}
                                        sx={{ borderBottom: "1px solid black" }}
                                        onClick={() => handleDetail(item)}
                                        cursor="pointer"
                                    >
                                        <Td><Image src={''} mr="4px" />{item.cardNumber || ""}</Td>
                                        <Td>{item.isDefault && (<Badge variant='subtle' color={'info.info'} borderRadius={'4px'} backgroundColor={'info.onInfo'} fontSize={'xs'} textTransform="none">
                                            Default Card
                                        </Badge>)}</Td>
                                        <Td display={{ base: "none", md: "table-cell" }}>Expires {item.expireDate}</Td>
                                        <Td textAlign={'end'}>
                                            <Button
                                                variant="link"
                                                onClick={() => handleDetail(item)}
                                                href="#"
                                            >
                                                <Icon as={Edit} w="24px" h="24px" ml={1} />
                                            </Button>
                                        </Td>
                                    </Tr>
                                ))
                            ) : (
                                <Tr>
                                    <Td colSpan={3} textAlign="center" py={10}>
                                        No Card Is Added
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            <Flex justifyContent={'flex-start'}>
                <Button variant="ghost" mt={4} size={'md'} colorScheme={'gray'} fontWeight={'normal'} rightIcon={<Add />}>Add Payment Method</Button>
            </Flex>
        </Card>
    );
}

export default PaymentMethodInfo;
