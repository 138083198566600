// Chakra imports
import React from "react";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { Flex, Text, Box, Icon } from "@chakra-ui/react";
import { ArrowLeft2 } from "iconsax-react";

function PaymentLayout(props) {
  const { children, onBack = "", backText = "", hasBackButton = false } = props;

  const handleBackButton = () => {
    if (onBack) {
      onBack();
    } else {
      window.history.back();
    }
  };

  return (
    <Flex
      position="relative"
      bgRepeat="no-repeat"
      backgroundSize="contain"
      bgSize="100%"
      backgroundPosition="0 50%"
      pb={{ base: "90px", lg: "40px" }}
    >
      <Flex
        w="100%"
        mx="auto"
        px={{ base: "20px", lg: "30px" }}
        justifyContent="start"
        direction="column"
      >
        {hasBackButton && (
          <Flex
            mt={{ base: "0px", lg: "20px" }}
            style={{ alignItems: "flex-end" }}
            onClick={handleBackButton}
            cursor={"pointer"}
            zIndex={10}
            h="48px"
            color="primary.primary"
          >
            <Box>
              <Icon
                as={ArrowLeft2}
                w="20px"
                h="20px"
                color="#000"
                marginRight={5}
              />
            </Box>

            {backText && (
              <Text fontWeight={500} fontSize={22}>
                {backText}
              </Text>
            )}
          </Flex>
        )}
        {children}
      </Flex>
    </Flex>
  );
}

export default PaymentLayout;
