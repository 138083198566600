// Chakra imports
import { Text, useColorModeValue, SimpleGrid, Box } from "@chakra-ui/react";
// Assets
import Project1 from "assets/img/profile/Project1.png";
import Project2 from "assets/img/profile/Project2.png";
import Project3 from "assets/img/profile/Project3.png";
// Custom components
import Card from "components/card/Card.js";
import { ArrowRight2 } from "iconsax-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Project from "views/admin/home/components/Project";

export default function Projects(props) {
  const { projects, title, TitleIcon, ...rest } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 2px 2px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US"); // Format to MM/DD/YYYY
  };

  return (
    <Box mb={{ base: "0px", md: "30px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text
          color={textColorPrimary}
          fontWeight="500"
          fontSize="md"
          my="20px"
          display="flex"
          alignItems="center"
          gap={2}
        >
          {TitleIcon}
          {title}
        </Text>
        <Text
          color="primary.primary"
          fontSize="sm"
          fontWeight={500}
          display="flex"
          gap={1}
          onClick={() => navigate("/admin/projects")}
          cursor="pointer"
        >
          View All <ArrowRight2 size={20} />
        </Text>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={6}>
        {projects?.slice(0, 3).map((project) => (
          <Project
            key={project?.id}
            boxShadow={cardShadow}
            address={project?.address}
            update={formatDate(project?.updated_at)}
            title={project?.name}
            status={project?.status}
            expert={project?.expert?.name}
            id={project?.id}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
}
