import React from "react";
import { Box } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes.js";
import BottomNavigation from "components/BottomNavigation/BottomNavigation";
import { useMediaQuery } from "@chakra-ui/react";

export default function SimpleLayout() {
  const getRoute = () => window.location.pathname !== "/admin/full-screen-maps";

  const renderRoutes = (routes) => {
    return routes.flatMap((route, key) =>
      route.layout === "/onboarding" ? (
        <Route path={route.path} element={route.component} key={key} />
      ) : route.collapse ? (
        renderRoutes(route.items)
      ) : null
    );
  };

  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <Box>
      <Box
        height="100%"
        position="relative"
        overflow="auto"
        w={{ base: "100%", xl: "100%" }}
        maxWidth={{ base: "100%", xl: "100%" }}
      >
        {getRoute() && (
          <Box>
            <Routes>
              {renderRoutes(routes)}
              <Route path="/" element={<Navigate to="/admin/home" replace />} />
            </Routes>
          </Box>
        )}
      </Box>
      <Box display={{ base: "none", md: "block" }}>
        <Footer />
      </Box>
      {isMobile && <BottomNavigation />}
    </Box>
  );
}
