import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Flex,
  Heading,
  useColorModeValue,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Tabs,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
} from "@chakra-ui/react";
import Button from "components/fields/Button";
import ProjectStatusBadge from "components/card/ProjectStatusBadge";
import ProjectDetailsDrawer from "./components/ProjectDetailsDrawer";
import { useGetAllProjects } from "services/projects/useGetAllProjects";
import Card from "components/card/Card";
import {
  ArrowLeft2,
  DocumentText,
  Profile2User,
  Location,
  ArrowRight2,
} from "iconsax-react";

export default function ProjectDetails() {
  const navigate = useNavigate();
  const [project, setProject] = useState([]);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const { data } = useGetAllProjects({ enabled: true });

  const projects = data ?? [];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US"); // Format to MM/DD/YYYY
  };

  useEffect(() => {
    const projectId = new URLSearchParams(window.location.search).get(
      "projectId"
    );
    const currentProject = data?.find((proj) => proj.id === projectId);
    setProject(currentProject || {});
  }, [data]);

  const handleEditProject = () => {
    navigate(`/onboarding/edit-project?parent=list&projectId=${project.id}`);
  };

  return (
    <Box color="primary.primary">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        px={{ base: "16px", md: 0 }}
        backgroundColor={{ base: "#fff", md: "transparent" }}
        h="48px"
      >
        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="500"
          display="flex"
          alignItems="center"
          gap={1}
          cursor="pointer"
          onClick={() => navigate("/admin/projects")}
        >
          <ArrowLeft2 fontSize={24} />
          Project Details
        </Heading>
        <Button
          bgColor="#203764"
          textColor="#fff"
          rightIcon={<ArrowRight2 />}
          variant="brand"
          hideBelow="md"
        >
          Continue Inspection
        </Button>
      </Flex>

      <Flex
        mt={{ base: 0, md: "50px" }}
        mb="20px"
        mx={{ base: "16px", md: "0" }}
        alignItems="center"
        justifyContent={{ base: "space-between", md: "initial" }}
      >
        <Text size="md" fontWeight="bold" display="flex" gap={2}>
          <DocumentText variant="Bold" />
          {project.name}
        </Text>
        <Box
          px="40px"
          py="6px"
          bgColor="#E2E8F0"
          mx="20px"
          fontWeight="bold"
          textColor="#A0AEC0"
          borderRadius={8}
        >
          SB721
        </Box>
      </Flex>

      <Flex display={{ base: "none", md: "flex" }}>
        <Table
          variant="simple"
          colorScheme="brand"
          size="md"
          backgroundColor="#fff"
          borderRadius="2xl"
          layout="fixed"
          wordBreak="break-word"
        >
          <Thead bg="#EBEFFF" textColor={textColor}>
            <Tr>
              <Th>Address</Th>
              <Th>Total EEEs</Th>
              <Th>Balcony</Th>
              <Th>Stair</Th>
              <Th>Walkway</Th>
              <Th width="150px">Project Status</Th>
              <Th>Inspection Day</Th>
              <Th>Expert</Th>
              <Th>Customer</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td wordBreak="break-word" overflowWrap="break-word">
                {project?.address?.length > 30
                  ? `${project.address.slice(0, 30)}...`
                  : project.address}
              </Td>
              <Td>0</Td>
              <Td>0</Td>
              <Td>0</Td>
              <Td>0</Td>
              <Td>
                <ProjectStatusBadge status={project.status} />
              </Td>
              <Td>0</Td>
              <Td>{project.expert?.name || "N/A"}</Td>
              <Td>
                {`${project.customer?.first_name ?? ""} ${project.customer?.last_name ?? ""}`}
              </Td>
              <Td cursor="pointer" onClick={handleEditProject}>
                <ArrowRight2 />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Flex>

      <Box
        m={4}
        p={4}
        borderRadius={8}
        display={{ base: "block", md: "none" }}
        backgroundColor="#fff"
        position="relative"
      >
        <Box position="absolute" right={4}>
          <ProjectStatusBadge status={project.status} />
        </Box>
        <Flex alignItems="center" gap={2} pb={2}>
          <Text
            fontWeight="bold"
            fontSize="sm"
            me="4px"
            my="5px"
            display="flex"
            gap={1}
            alignItems="center"
          >
            <Profile2User variant="Bold" />
            Total EEEs
          </Text>
          :
          <Text fontWeight="500" fontSize="sm" me="4px" my="5px">
            expert
          </Text>
        </Flex>
        <Flex alignItems="center" gap={2} py={2}>
          <Text
            fontWeight="bold"
            fontSize="sm"
            me="4px"
            my="5px"
            display="flex"
            alignItems="center"
            gap={1}
          >
            <Location variant="Bold" />
            Address
          </Text>
          :
          <Text fontWeight="500" fontSize="sm" me="4px" my="5px" isTruncated>
            {project?.address?.length > 30
              ? `${project.address.slice(0, 30)}...`
              : project.address}
          </Text>
        </Flex>

        <Box
          position="absolute"
          right="0"
          bottom="0"
          pr={4}
          pb={7}
          pl={2}
          borderRadius={8}
          display="flex"
          alignItems="center"
          fontWeight={500}
          cursor="pointer"
          onClick={() => setIsMobileDrawerOpen(true)}
          backgroundColor="#fff"
        >
          View <ArrowRight2 size={20} />
        </Box>
      </Box>

      <Tabs mx={{ base: "16px", md: "0" }} mt="30px">
        <TabList>
          <Tab w="100%" fontWeight="500">
            EEE Details
          </Tab>
          <Tab w="100%" fontWeight="500">
            Building Details
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <TableContainer>
              <Table
                variant="simple"
                colorScheme="brand"
                size={{ base: "sm", md: "md" }}
              >
                <Thead>
                  <Tr>
                    <Th bg="#EBEFFF" textColor={textColor}>
                      EEE ID
                    </Th>
                    <Th bg="#EBEFFF" textColor={textColor} textAlign="center">
                      Type Count
                    </Th>
                    <Th bg="#EBEFFF" textColor={textColor} textAlign="center">
                      Building Status
                    </Th>
                    <Th bg="#EBEFFF" textColor={textColor}>
                      Floor Date
                    </Th>
                    <Th bg="#EBEFFF" textColor={textColor}>
                      Unit
                    </Th>
                    <Th bg="#EBEFFF" textColor={textColor}>
                      Inspection Status
                    </Th>
                    <Th bg="#EBEFFF" textColor={textColor}>
                      Inspection Result
                    </Th>
                  </Tr>
                </Thead>
                {/* <Tbody>
                    {projects.map((item) => (
                      <Tr key={item.id}>
                        <Td>
                          <Text fontWeight="bold">{item.name}</Text>
                          <Flex mt="8px">
                            <Text fontSize="14px">{item.address}</Text>
                          </Flex>
                        </Td>
                        <Td textAlign="center">0</Td>
                        <Td textAlign="center">
                          <ProjectStatusBadge status={item.status} />
                        </Td>
                        <Td>{formatDate(item.created_at)}</Td>
                        <Td>{item.expert?.name}</Td>
                        <Td>{`${item.customer?.first_name} ${item.customer?.last_name}`}</Td>
                        <Td>
                          <Button variant="link">View Details {" >"}</Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody> */}
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <Card />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <ProjectDetailsDrawer
        isOpen={isMobileDrawerOpen}
        onClose={() => setIsMobileDrawerOpen(false)}
        project={project}
        editProject={handleEditProject}
      />
    </Box>
  );
}
