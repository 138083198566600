// Chakra Imports
import { Button, Icon, useColorMode } from "@chakra-ui/react";
import { Moon, Sun1 } from "iconsax-react";
// Custom Icons
import React from "react";

export default function FixedPlugin(props) {
  const { ...rest } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  let bgButton = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";

  return (
    <Button
      {...rest}
      h="60px"
      w="60px"
      zIndex="99"
      bg={bgButton}
      position="fixed"
      variant="no-effects"
      left={document.documentElement.dir === "rtl" ? "35px" : ""}
      right={document.documentElement.dir === "rtl" ? "" : "35px"}
      bottom="30px"
      border="1px solid"
      borderColor="#6A53FF"
      borderRadius="50px"
      onClick={toggleColorMode}
      display="flex"
      p="0px"
      align="center"
      justify="center"
    >
      {colorMode === "light" ? (
        <Moon variant="Bold" color="#fff" />
      ) : (
        <Sun1 variant="Bold" color="#fff" />
      )}
    </Button>
  );
}
