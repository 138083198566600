import { Button, Card, Stack, Text } from "@chakra-ui/react";
import { Edit } from "iconsax-react";
import React from "react";

const Question = ({ number, title }) => {
  return (
    <Card p="24px" my="24px" color="primary.primary">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text>
          {number}. {title}
        </Text>
        <Button fontWeight="normal" variant="ghost" rightIcon={<Edit />}>
          Edit Question
        </Button>
      </Stack>
    </Card>
  );
};

export default Question;
