import React, { useState } from "react";
import {
    Box,
    Card,
    Flex,
    Text,
    Button,
    useColorModeValue,
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Skeleton,
    Collapse,
    Spinner,
} from "@chakra-ui/react";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { motion } from "framer-motion";

const MotionBox = motion.create(Box);

const tableHeaders = [
    { text: "Project Name" },
    { text: "Report Price" },
    { text: "EEE Count" },
    { text: "Project Address" },
];

const isLoading = false;
const projects = [{ project_name: 'a', price: 120, count: 4, address: 'Project Address Project Address' }];

function PlanHistory() {
    const [showDetails, setShowDetails] = useState(false);
    const textColor = useColorModeValue("navy.700", "white");

    const handleDetail = (item) => {
        console.log(item);
    };

    return (
        <Card px={6} py={8} width={'100%'}>
            <Text fontSize={'2xl'} color={'primary.primary'} fontWeight={'medium'} mb={4}>Current Plan</Text>
            <Text fontSize={'2xl'} color={'primary.primary'}>Repotel Monthly Subscription</Text>
            <Text fontSize="md" color={'text.paragraph'} mt={'8px'}>$49 per Month with $20 per EEE</Text>
            <Flex justifyContent={'flex-start'} mt={4}>
                <Button
                    onClick={() => setShowDetails(!showDetails)}
                    variant="ghost"
                    size={'md'}
                    colorScheme={'gray'}
                    fontWeight={'normal'}
                    rightIcon={showDetails ? <ArrowDown2 /> : <ArrowUp2 />}
                >
                    EEEs Details
                </Button>
            </Flex>
            <Collapse in={showDetails} animate>
                <MotionBox
                    overflowX="auto"
                    display={{ base: "none", md: "block" }}
                    mt={2}
                    p={0}
                    borderRadius={15}
                    backgroundColor="white"
                    boxShadow="sm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                    <TableContainer>
                        <Table variant="simple" colorScheme="brand" size="sm">
                            <Thead h={16}>
                                <Tr>
                                    {tableHeaders.map((item, index) => (
                                        <Th
                                            key={index}
                                            bg="#EBEFFF"
                                            textColor={textColor}
                                            textTransform={"none"}
                                            fontSize="sm"
                                            fontWeight="500"
                                        >
                                            <Flex alignItems="center">
                                                <Text>{item.text}</Text>
                                            </Flex>
                                        </Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {isLoading ? (
                                    Array.from({ length: 4 }).map((_, index) => (
                                        <Tr key={index} h={12}>
                                            <Td>
                                                <Skeleton height="20px" />
                                            </Td>
                                            <Td>
                                                <Skeleton height="20px" />
                                            </Td>
                                            <Td>
                                                <Skeleton height="20px" />
                                            </Td>
                                            <Td>
                                                <Skeleton height="20px" />
                                            </Td>
                                        </Tr>
                                    ))
                                ) : projects.length > 0 ? (
                                    projects.map((item, index) => (
                                        <Tr
                                            h={16}
                                            key={index}
                                            _hover={{ bg: "#f7f7f7" }}
                                            sx={{ borderBottom: "1px solid black" }}
                                            onClick={() => handleDetail(item)}
                                            cursor="pointer"
                                        >
                                            <Td>{item.project_name || ""}</Td>
                                            <Td>{item.price || ""}</Td>
                                            <Td>{item.count || ""}</Td>
                                            <Td>{item.address || ""}</Td>
                                        </Tr>
                                    ))
                                ) : (
                                    <Tr>
                                        <Td colSpan={5} textAlign="center" py={10}>
                                            No Report Is Added
                                        </Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </MotionBox>
                <MotionBox
                    display={{ base: "block", md: "none" }}
                    mt={4}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1}}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                    <Stack spacing={4}>
                        {isLoading ? (
                            <Box
                                borderRadius="lg"
                                overflow="hidden"
                                p={6}
                                bg="white"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Spinner size="lg" color={textColor} />
                            </Box>
                        ) : projects.length > 0 ? (
                            projects.map((item, index) => (
                                <Box
                                    key={index}
                                    borderRadius="lg"
                                    overflow="hidden"
                                    p={4}
                                    bg="white"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    onClick={() => handleDetail(item)}
                                >
                                    <Flex alignItems="center">
                                        <Text>
                                            {item.project_name || "-"}
                                        </Text>
                                    </Flex>
                                    <Flex alignItems="center">
                                        <Text>
                                            {item.price || "-"}
                                        </Text>
                                    </Flex>
                                    <Button
                                    key={item.id}
                                    variant="link"
                                    onClick={() => handleDetails(item)}
                                    href="#"
                                >
                                    <Flex alignItems="center">
                                    </Flex>
                                </Button>
                                </Box>
                            ))
                        ) : (
                            <Box
                                borderRadius="lg"
                                overflow="hidden"
                                p={6}
                                bg="white"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                No Report Is Added
                            </Box>
                        )}
                    </Stack>
                </MotionBox>
            </Collapse>
            <Flex justifyContent={'space-between'} alignItems={'center'} mt={6}>
                <Text fontSize="md" color={'text.paragraph'}>Your plan renews on 1/15/2024</Text>
                <Button
                    variant="outline"
                    size={'md'}
                    colorScheme={'gray'}
                    fontWeight={'normal'}
                    borderRadius={'10px'}
                    borderColor='primary.primary'
                >
                    Cancel Plan
                </Button>
            </Flex>
        </Card>
    );
}

export default PlanHistory;
