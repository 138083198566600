import { Box, Text, Image, useTheme } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Crown } from "components/icons/Icons";
import { ArrowRight2 } from "iconsax-react";
import cardBgImg from "assets/img/subscription/cardbg.png";

function PaymentBox({
  subscriptionState = "free",
  reportLeft = 3,
  isSidebarExpanded = true,
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  const colors = {
    premium: {
      bg: theme.colors.warning.warning,
      color: theme.colors.warning.onWarning,
      iconColor: theme.colors.warning.onWarning,
    },
    expired: {
      bg: theme.colors.info.tritary,
      color: theme.colors.info.info,
      iconColor: theme.colors.info.info,
    },
    free: {
      bg: theme.colors.secondary.onSecondary,
      color: theme.colors.secondary.secondary,
      iconColor: theme.colors.secondary.secondary,
    },
  };

  const currentColors = colors[subscriptionState] || colors.free;

  const handleClick = () => {
    navigate("/onboarding/plans");
  };

  return (
    <Box
      mb="20px"
      onClick={handleClick}
      cursor="pointer"
      transition="all 0.5s ease"
    >
      <Box
        position="relative"
        p="20px"
        bg={currentColors.bg}
        color={currentColors.color}
        fontSize="sm"
        fontWeight="medium"
        textAlign="center"
        overflow="hidden"
      >
        <Image
          src={cardBgImg}
          alt="Background Image"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          opacity="0.1"
          zIndex="1"
        />
        <Box
          zIndex="2"
          display="flex"
          gap="6px"
          justifyContent={{ base: "space-between", md: "center" }}
          alignItems="center"
        >
          <Box
            display="flex"
            gap="6px"
            justifyContent={{ base: "start", md: "center" }}
            alignItems="center"
            flexDirection={isSidebarExpanded ? "row" : "column"}
          >
            <Crown h="25px" w="25px" color={currentColors.iconColor} />
            {isSidebarExpanded ? (
              <Text>
                {subscriptionState === "free"
                  ? `${reportLeft}/3 Free Reports`
                  : subscriptionState === "premium"
                    ? "Upgrade to PRO"
                    : "Repolet PRO"}
              </Text>
            ) : (
              <Text>
                {subscriptionState === "free"
                  ? `${reportLeft}/3`
                  : subscriptionState === "premium"
                    ? "Upgrade to PRO"
                    : "Repolet PRO"}
              </Text>
            )}
          </Box>
          <ArrowRight2 color={currentColors.iconColor} size={20} />
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentBox;
