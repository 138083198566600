import { useQuery } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

export const getUser_service = async (token) => {
  try {
    const response = await fetch(API_URLS.get_user, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user information");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Get user error:", error);
    throw error;
  }
};

export const useGetUser = ({ token, staleTime }) => {
  return useQuery({
    queryKey: ["user", token],
    queryFn: () => getUser_service(token),
    enabled: !!token,
    staleTime: staleTime,
  });
};
