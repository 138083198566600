import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import Button from "components/fields/Button";
import { Add } from "iconsax-react";
import FormCard from "./components/FormCard";
import { useNavigate } from "react-router-dom";

export default function Forms() {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor={{ base: "#fff", md: "transparent" }}
        px={{ base: "16px", md: "0" }}
        h="48px"
        mb={4}
      >
        <Heading
          as="h1"
          fontSize={{ base: "xl", md: "2xl" }}
          fontWeight={500}
          color={"#203764"}
        >
          Inspection Forms
        </Heading>
        <Button
          bgColor="#203764"
          textColor="#fff"
          leftIcon={<Add />}
          variant="brand"
          hideBelow="md"
          fontWeight="medium"
          onClick={() => navigate("add-form")}
          px="24px"
        >
          Add New Form
        </Button>
        <Button
          variant="link"
          onClick={null}
          hideFrom="md"
          backgroundColor="transparent"
          pr="0"
        >
          <Add size={26} />
        </Button>
      </Box>

      <Box m={{ base: 4, md: 0 }}>
        <FormCard
          title="Repolet Inspection Form"
          badges={[
            { name: "Active Form", color: "green" },
            { name: "System Default", color: "blue" },
          ]}
          numberOfProjects={3}
        />
      </Box>
    </Box>
  );
}
