import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Flex,
  Box,
  Table,
  Tr,
  Tbody,
  Td,
} from "@chakra-ui/react";
import Button from "components/fields/Button";

function ProjectDetailsDrawer({ isOpen, onClose, editProject, project }) {
  return (
    <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius={36}>
        <DrawerHeader textAlign={"center"}>Project Details</DrawerHeader>

        <DrawerBody color={"#203764"}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Box fontWeight={"semibold"}>{project.name}</Box>
            <Box
              px="40px"
              py="10px"
              bgColor="#E2E8F0"
              fontWeight="bold"
              textColor="#A0AEC0"
              borderRadius={4}
            >
              SB721
            </Box>
          </Flex>
          <Table variant="unstyled" size={"sm"}>
            <Tbody>
              <Tr>
                <Td pl={0} fontWeight={"semibold"}>
                  Address
                </Td>
                <Td fontWeight={"semibold"}>:</Td>
                <Td pr={0}>{project.address}</Td>
              </Tr>
              <Tr>
                <Td pl={0} fontWeight={"semibold"}>
                  Total EEEs
                </Td>
                <Td fontWeight={"semibold"} fontWeight={"semibold"}>
                  :
                </Td>
                <Td pr={0}>0</Td>
              </Tr>
              <Tr>
                <Td pl={0} fontWeight={"semibold"}>
                  Balcony
                </Td>
                <Td fontWeight={"semibold"}>:</Td>
                <Td pr={0}>0</Td>
              </Tr>
              <Tr>
                <Td pl={0} fontWeight={"semibold"}>
                  Stairs
                </Td>
                <Td fontWeight={"semibold"}>:</Td>
                <Td pr={0}>0</Td>
              </Tr>
              <Tr>
                <Td pl={0} fontWeight={"semibold"}>
                  Walkways
                </Td>
                <Td fontWeight={"semibold"}>:</Td>
                <Td pr={0}>0</Td>
              </Tr>
              <Tr>
                <Td pl={0} fontWeight={"semibold"}>
                  Inspection Day
                </Td>
                <Td fontWeight={"semibold"}>:</Td>
                <Td pr={0}>0</Td>
              </Tr>
              <Tr>
                <Td pl={0} fontWeight={"semibold"}>
                  Expert
                </Td>
                <Td fontWeight={"semibold"}>:</Td>
                <Td pr={0}>{project.expert?.name}</Td>
              </Tr>
              <Tr>
                <Td pl={0} fontWeight={"semibold"}>
                  Customer
                </Td>
                <Td fontWeight={"semibold"}>:</Td>
                <Td pr={0}>
                  {project.customer?.first_name +
                    " " +
                    project.customer?.last_name}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" w="100%" onClick={editProject}>
            Edit Project
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default ProjectDetailsDrawer;
