import api from "../apiService";
import { useMutation } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

const editProfile = async (payload) => {
  const formData = new FormData();
  formData.append("name", payload.company_name);
  formData.append("address", payload.company_address);
  formData.append("phone_number", payload.company_phone);
  formData.append("description", payload.description);

  if (payload.company_logo) {
    formData.append("logo", payload.company_logo);
  }

  const response = await api.post(`${API_URLS.edit_profile}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export default function useEditProfile() {
  return useMutation({
    mutationFn: editProfile,
  });
}
