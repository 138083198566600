/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import {
  Box,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  // let activeColor = useColorModeValue("gray.700", "white");
  let activeColor = useColorModeValue("#0C0D12", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("#0C0D12", "white");
  // let textColor = useColorModeValue("secondaryGray.500", "white");

  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes, isSidebarExpanded } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const visibleRoutes = routes.filter((route) => !route.nolink);

  const createLinks = (routes) => {
    const { colorMode } = useColorMode();
    return visibleRoutes.map((route, index) => {
      if (route.category) {
        return (
          <>
            {isSidebarExpanded && (
              <Text
                fontSize={"md"}
                color={activeColor}
                fontWeight="bold"
                mx="auto"
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                pt="18px"
                pb="12px"
                key={index}
              >
                {route.name}
              </Text>
            )}
            {createLinks(route.items)}
          </>
        );
      } else if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return !route.mobileOnly ? (
          <NavLink key={index} to={route.layout + route.path}>
            {route.icon ? (
              <Box transition=" all 0.5s ease">
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                >
                  <Flex
                    w="100%"
                    p="16px"
                    borderRadius="sm"
                    borderBottom="solid #fcfcfc 1px"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor={
                      activeRoute(route.path.toLowerCase())
                        ? colorMode === "dark"
                          ? "gray.700"
                          : "#E4EAF6"
                        : colorMode === "dark"
                          ? "gray.700"
                          : "#F5F5F5"
                    }
                  >
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      mr={isSidebarExpanded ? "10px" : 0}
                    >
                      <route.icon
                        size={24}
                        color="#203764"
                        variant={
                          activeRoute(route.path.toLowerCase())
                            ? "Bold"
                            : "Outline"
                        }
                      />
                    </Box>
                    {isSidebarExpanded && (
                      <Text
                        fontSize="md"
                        me="auto"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : textColor
                        }
                        fontWeight={
                          activeRoute(route.path.toLowerCase()) ? "500" : "400"
                        }
                      >
                        {route.name}
                      </Text>
                    )}
                  </Flex>
                  {/* <Box
                    h="36px"
                    w="4px"
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius="5px"
                  /> */}
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py="5px"
                  ps="10px"
                >
                  <Text
                    me="auto"
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                  >
                    {route.name}
                  </Text>
                  <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                </HStack>
              </Box>
            )}
          </NavLink>
        ) : null;
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
