import React from "react";
import { Flex } from "@chakra-ui/react";
import PaymentLayout from "layouts/payment";
import PaymentMethodInfo from "components/payment/PaymentMethodInfo";
import BillingAddressInfo from "components/payment/BillingAddressInfo";
import PlanHistory from "components/payment/PlanHistory";
import InvoiceHistory from "components/payment/InvoiceHistory";

function SubscriptionSettings() {

  return (
    <PaymentLayout backText="Subscription Settings" hasBackButton>
      <Flex
        mx="auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        w={{ base: "100%", lg: "800px" }}
        borderRadius="10px"
        gap={6}
        mt={'30px'}
      >
        <PlanHistory />

        <PaymentMethodInfo />

        <BillingAddressInfo />

        <InvoiceHistory />
      </Flex>
    </PaymentLayout>
  );
}

export default SubscriptionSettings;
