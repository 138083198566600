import { Badge, Button, Card, Flex, Stack, Text } from "@chakra-ui/react";
import { ArrowRight2, DocumentText } from "iconsax-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const FormCard = ({ title, badges, numberOfProjects }) => {
  const navigate = useNavigate();

  return (
    <Card
      p="24px"
      my="24px"
      color="primary.primary"
      cursor="pointer"
      onClick={() => {
        navigate(`form-details?id=${title}&column=General+Information`);
      }}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text>{title}</Text>
        <Stack direction="row">
          {badges.map((badge) => (
            <Badge key={badge.name} color={badge.color}>
              {badge.name}
            </Badge>
          ))}
        </Stack>
        <Flex gap={1}>
          <DocumentText variant="Bold" /> <Text>Used In</Text> :{" "}
          {numberOfProjects || 0} Projects
        </Flex>
        <Button rightIcon={<ArrowRight2 />} fontWeight="normal" variant="ghost">
          View
        </Button>
      </Flex>
    </Card>
  );
};

export default FormCard;
