import { Box, useMediaQuery } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes.js";
import BottomNavigation from "components/BottomNavigation/BottomNavigation";

export default function Dashboard(props) {
  const { ...rest } = props;
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const toggleSidebar = () => setIsSidebarExpanded(!isSidebarExpanded);

  const getActiveAttribute = (routes, attribute) => {
    for (const route of routes) {
      if (route.collapse || route.category) {
        const nestedResult = getActiveAttribute(route.items, attribute);
        if (nestedResult) return nestedResult;
      } else if (window.location.href.includes(route.layout + route.path)) {
        return route[attribute];
      }
    }
    return attribute === "name" ? "Home" : false;
  };

  const renderRoutes = (routes) =>
    routes.flatMap((route, index) =>
      route.layout === "/admin" ? (
        <Route path={route.path} element={route.component} key={index} />
      ) : route.collapse ? (
        renderRoutes(route.items)
      ) : null
    );

  return (
    <Box>
      <SidebarContext.Provider value={null}>
        <Sidebar
          isSidebarExpanded={isSidebarExpanded}
          toggleSidebar={toggleSidebar}
          routes={routes}
          {...rest}
        />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          transition=" all 0.5s ease"
          w={
            isSidebarExpanded
              ? { base: "100%", xl: "calc( 100% - 256px )" }
              : { base: "100%", xl: "calc( 100% - 88px )" }
          }
          maxWidth={
            isSidebarExpanded
              ? { base: "100%", xl: "calc(100% - 256px)" }
              : { base: "100%", xl: "calc( 100% - 88px )" }
          }
        >
          {window.location.pathname !== "/admin/full-screen-maps" && (
            <Box
              mx="auto"
              p={{ md: "30px" }}
              pe={{ md: "20px" }}
              minH="100vh"
              pt={{ md: "32px" }}
            >
              <Routes>
                {renderRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/admin/home" replace />}
                />
              </Routes>
            </Box>
          )}
          <Footer />
        </Box>
      </SidebarContext.Provider>
      {isMobile && <BottomNavigation />}
    </Box>
  );
}
