import "./assets/css/App.css";
import { Routes, Route, Navigate } from "react-router-dom";

import {} from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import SimpleLayout from "./layouts/admin/SimpleLayout";
import {
  ChakraProvider,
  // extendTheme
} from "@chakra-ui/react";
import initialTheme from "./theme/theme"; //  { themeGreen }
import { useState } from "react";

import { AppContext } from "contexts/AppContext";
import ProtectedRoute from "components/HOC/ProtectedRoute";

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  return (
    <AppContext>
      <ChakraProvider theme={currentTheme}>
        <Routes>
          <Route path="auth/*" element={<AuthLayout />} />
          <Route
            path="admin/*"
            element={
              <ProtectedRoute
                element={
                  <AdminLayout
                    theme={currentTheme}
                    setTheme={setCurrentTheme}
                  />
                }
              />
            }
          />
          <Route
            path="onboarding/*"
            element={
              <ProtectedRoute
                element={
                  <SimpleLayout
                    theme={currentTheme}
                    setTheme={setCurrentTheme}
                  />
                }
              />
            }
          />
          <Route path="/" element={<Navigate to="/admin" replace />} />
        </Routes>
      </ChakraProvider>
    </AppContext>
  );
}
