// chakra imports
import { Box, Flex, Icon, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";
import SubscriptionBox from "../../payment/PaymentBox";
import { ArrowSquareLeft, ArrowSquareRight } from "iconsax-react";

// FUNCTIONS

function SidebarContent(props) {
  const { routes, toggleSidebar, isSidebarExpanded } = props;
  // SIDEBAR
  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      px="16px"
      borderRadius="30px"
    >
      <Icon
        as={isSidebarExpanded ? ArrowSquareLeft : ArrowSquareRight}
        onClick={toggleSidebar}
        cursor="pointer"
        fontSize={26}
        mx="auto"
      />
      <Brand isSidebarExpanded={isSidebarExpanded} />
      <Box pe={{ md: "16px", "2xl": "1px" }} hideBelow="xl">
        <SubscriptionBox isSidebarExpanded={isSidebarExpanded} />
      </Box>
      <Stack direction="column" mb="auto" mt="8px">
        <Box pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} isSidebarExpanded={isSidebarExpanded} />
        </Box>
      </Stack>

      <Box
        mt="60px"
        mb="40px"
        borderRadius="30px"
        justifyContent="center"
        display="flex"
      >
        <SidebarCard isSidebarExpanded={isSidebarExpanded} />
      </Box>
    </Flex>
  );
}

export default SidebarContent;
