// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import Button from "components/fields/Button";
import { Add } from "iconsax-react";

// Assets
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ActionCard(props) {
  const { image, name, author, bidders, button_name, link } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate(link);
  };

  return (
    <Card p="32px">
      <Flex
        direction={{ base: "column" }}
        justify="center"
        gap="24px"
        alignItems="center"
      >
        <Image
          src={image}
          w={{ base: "150px", md: "200px" }}
          h={{ base: "150px", md: "200px" }}
        />

        <Text
          color={textColor}
          fontSize={{
            base: "2xl",
            md: "2xl",
          }}
          fontWeight="medium"
          me="14px"
        >
          {name}
        </Text>
        <Text
          color="secondaryGray.600"
          fontSize={{
            base: "sm",
          }}
          fontWeight="400"
          me="14px"
        >
          {author}
        </Text>
        <AvatarGroup max={3} color={textColorBid} size="sm" fontSize="12px">
          {bidders.map((avt, key) => (
            <Avatar key={key} src={avt} />
          ))}
        </AvatarGroup>
        {button_name != "" ? (
          <Button
            bgColor="#203764"
            width="100%"
            textColor="#fff"
            leftIcon={<Add />}
            onClick={handleClick}
          >
            {button_name}
          </Button>
        ) : null}
      </Flex>
    </Card>
  );
}
