import { Box, Card, Flex, Grid, Heading, Icon, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import React from "react";
import {
  ArrowLeft2,
  Call,
  DocumentText,
  LogoutCurve,
  Profile2User,
  Sms,
  ArrowRight2,
  UserCirlceAdd,
  UserSquare,
} from "iconsax-react";
import { useGetAllProjects } from "services/projects/useGetAllProjects";
import { useGetExperts } from "services/experts/useGetExperts";
import { useGetAllCustomers } from "services/customers/useGetAllCustomers";
import Button from "components/fields/Button";
import { useNavigate } from "react-router-dom";
import { useGetUser } from "services/profile/useGetUser";

export default function Overview() {
  const userState = useSelector((state) => state.auth);
  const { data: userData } = useGetUser({
    token: userState.user.token,
  });
  const company = userData?.data.company;
  const { data: projects = [] } = useGetAllProjects({ enabled: true }) || {};
  const { data: experts = [] } = useGetExperts({ enabled: true }).data || {};
  const { data: customers = [] } =
    useGetAllCustomers({ enabled: true }).data || {};

  const numberOfProjects = projects?.length;
  const numberOfExpert = experts?.length;
  const numberOfCustomers = customers?.length;

  const navigate = useNavigate();

  const handleSubscriptionClick = () => {
    navigate("/onboarding/subscription_settings");
  };

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        px={{ base: "16px", md: 0 }}
        backgroundColor={{ base: "#fff", md: "transparent" }}
        h="48px"
      >
        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="500"
          display="flex"
          alignItems="center"
          gap={1}
          cursor="pointer"
          onClick={() => navigate("/admin/home")}
          color="primary.primary"
        >
          <ArrowLeft2 fontSize={24} />
          Settings
        </Heading>
        <Button
          bgColor="#203764"
          textColor="#fff"
          rightIcon={<LogoutCurve />}
          variant="brand"
          hideBelow="md"
        >
          Log Out
        </Button>
      </Flex>

      <Flex flexDirection={"column"} mx={{ base: "16px", lg: 0 }}>
        <Card
          mt={{ base: 4, md: "50px" }}
          width="auto"
          py={{ base: 0, md: 8 }}
          px={{ base: 4, md: 8 }}
        >
          <Flex
            display="flex"
            justifyContent="space-between"
            alignItems={{ base: "initial", md: "center" }}
            direction={{ base: "column", xl: "row" }}
            color="primary.primary"
          >
            <Flex
              gap={1}
              fontSize={"16px"}
              alignItems={"center"}
              py={{ base: 4, md: 0 }}
            >
              <Icon
                as={UserCirlceAdd}
                fontSize={52}
                onClick={() => navigate("edit-profile")}
                cursor="pointer"
              />
              <Text fontWeight="bold">{company?.name}</Text>
            </Flex>
            <Flex fontSize={"16px"} gap={4} hideFrom={"md"} py={1}>
              <Flex alignItems={"center"} gap={1}>
                <Call variant="Bold" color="#203764" size={20} />
                <Text fontWeight="medium">Phone: </Text>
              </Flex>
              <Text>{company?.phone_number}</Text>
            </Flex>
            <Flex fontSize={"16px"} gap={4} py={1}>
              <Flex alignItems={"center"} gap={1}>
                <Sms variant="Bold" color="#203764" size={20} />
                <Text fontWeight={"medium"}>Email :</Text>
              </Flex>
              <Text>{userData?.data?.user?.email}</Text>
            </Flex>
            <Flex fontSize={"16px"} gap={4} py={1}>
              <Flex alignItems={"center"} gap={1}>
                <DocumentText variant="Bold" color="#203764" size={20} />
                <Text fontWeight="medium">Project Number :</Text>
              </Flex>
              <Text>{numberOfProjects}</Text>
            </Flex>
            <Flex fontSize={"16px"} gap={4} py={1}>
              <Flex alignItems={"center"} gap={1}>
                <Profile2User variant="Bold" color="#203764" size={20} />
                <Text fontWeight="medium">Expert Count :</Text>
              </Flex>
              <Text>{numberOfExpert}</Text>
            </Flex>
            <Flex fontSize={"16px"} gap={4} py={1}>
              <Flex alignItems={"center"} gap={1}>
                <Profile2User variant="Bold" color="#203764" size={20} />
                <Text fontWeight="medium">Customer Count :</Text>
              </Flex>
              <Text>{numberOfCustomers}</Text>
            </Flex>
            <Box my={{ base: 4, md: 0 }}>
              <Button
                onClick={() => navigate("edit-profile")}
                variant={{ base: "brand", md: "outline" }}
                w="100%"
                size={{ base: "md", md: "xl" }}
                fontWeight="semibold"
                py="16px"
              >
                Edit
              </Button>
            </Box>
          </Flex>
        </Card>
        <Card mt="20px" width="auto" py={8} borderRadius={"10px"}>
          <Flex
            display="flex"
            justifyContent="space-between"
            alignItems={{ base: "initial", md: "center" }}
            onClick={() => handleSubscriptionClick()}
            cursor={"pointer"}
            px={4}
          >
            <Flex alignItems="center">
              <Text fontSize={"md"} color={"text.title"}>
                Subscription Settings
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Text fontSize={"sm"} color={"primary.primary"}>
                View{" "}
              </Text>
              <Icon as={ArrowRight2} w="16px" h="16px" ml={1} />
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </Box>
  );
}