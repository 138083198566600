
const initialState = {
  objects: null
};

// Define the reducer
const localReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DYNAMIC":
      return {
        objects: action.payload,
      };
    default:
      return state;
  }
};

export default localReducer;
