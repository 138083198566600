import React from "react";
import { Crown } from "components/icons/Icons";
import CButton from "components/fields/Button";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Flex,
  Text,
  VStack,
  Badge,
  useTheme,
  useBreakpointValue,
} from "@chakra-ui/react";

const features = [
  "Unlimited Report Generation",
  "Customizable Checklists",
  "Insights Dashboard",
  "Secure Access Control",
];

const PlanCard = ({ title, price, perEee, discount, onClick = '', minimized = false }) => {
  const theme = useTheme();
  const showFeatures = useBreakpointValue({ base: !minimized, lg: true });

  return (
    <Card
      borderRadius="20px"
      bg={theme.colors.surface.white}
      boxShadow='base'
      width={{ base: "100%", lg: "374px" }}
      px={{ base: "24px", md: "32px" }}
      py={{ base: "24px", md: "40px" }}
    >
      <CardHeader display='flex' justifyContent='space-between' alignItems='center' p="0" pb={'8px'}>
        <Text fontSize="md" fontWeight="medium" color={theme.colors.primary.onPrimary}>
          {title}
        </Text>
        {discount && <Badge
          bg={theme.colors.info.tritary}
          color={theme.colors.success.success}
          fontWeight="bold"
          borderRadius="4px"
          fontSize="sm"
          display='flex'
          alignItems='center'
        >
          <Crown h="18px" w="18px" color={theme.colors.success.success} me='4px' />
          <Text textTransform="none" >{discount}</Text>
        </Badge>}
      </CardHeader>
      <CardBody p="0">
        <Flex justifyContent="start" alignItems={'baseline'}>
          <Text fontSize={{ base: "2xl", md: "50px" }} fontWeight="bold" color={theme.colors.primary.onPrimary} letterSpacing="-2px" me={'8px'} lineHeight={{ base: "36px", md: "65px" }}>
            ${price}
          </Text>
          <Text fontSize={{ base: "sm", md: "md" }} fontWeight={minimized ? 'bold' : 'medium'} color={theme.colors.primary.onPrimary} letterSpacing="0.2px">
            / Monthly
          </Text>
        </Flex>
        <Flex justifyContent="start" alignItems={'baseline'} mb={{ base: "16px", md: "20px" }}>
          <Text fontSize={{ base: "2xl", md: "2xl" }} fontWeight="bold" color={theme.colors.primary.onPrimary} letterSpacing="-2px" me={'8px'} lineHeight={{ base: "30px", md: "36px" }}>
            ${perEee}
          </Text>
          <Text fontSize={{ base: "sm", md: "md" }} fontWeight={minimized ? 'bold' : 'medium'} color={theme.colors.primary.onPrimary} letterSpacing="0.2px">
            / Per EEE
          </Text>
        </Flex>
        {showFeatures && <VStack alignItems="flex-start" spacing={2}>
          {features.map((feature, index) => (
            <Flex key={index} alignItems={'center'} gap={'4px'}>
              <CheckIcon h="18px" w="18px" me='4px' />
              <Text fontSize={{ base: "sm", md: "md" }} color={theme.colors.primary.onPrimary}>
                {feature}
              </Text>
            </Flex>
          ))}
        </VStack>}
      </CardBody>
      {onClick && <CardFooter p="0" pt={{ base: "18px", md: "30px" }}>
        <CButton
          fontSize="base"
          variant="brand"
          fontWeight="500"
          w="100%"
          h="48px"
          onClick={onClick}
          // isLoading={isLoading}
          bgColor={theme.colors.brand['500']}
          textColor={'#E4EAF6'}
        // textColor={theme.colors.primary.primary}
        >
          Subscribe
        </CButton>
      </CardFooter>}
    </Card>
  );
};

export default PlanCard;
