import api from '../apiService';
import { useMutation } from '@tanstack/react-query';
import { API_URLS } from 'config/apiConfig';

const addCustomers = async (body) => {
  const response = await api.post(API_URLS.customers, body);

  return response;
};

export default function useAddCustomers() {
  return useMutation({
    mutationFn: addCustomers,
  });
}

