import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Box,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Card,
} from "@chakra-ui/react";
import * as Yup from "yup";
import Button from "components/fields/Button";
import { useNavigate } from "react-router-dom";
import { columns } from "../../FormDetails/data/columns";
import { Add, Minus } from "iconsax-react";

const StepOneSchema = Yup.object().shape({
  formName: Yup.string().required("Form Name is required"),
});

const StepTwoSchema = Yup.object().shape({
  section: Yup.string(),
});

const TwoStepForm = ({ activeStep, setActiveStep }) => {
  console.log(activeStep);

  const [showAddSection, setShowAddSection] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    formName: "",
    section: "",
  };

  const handleNext = (values, actions) => {
    actions.setTouched({});
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 1) {
      setActiveStep(activeStep - 1);
    } else {
      navigate("/admin/forms");
    }
  };

  const handleSubmit = (values) => {
    console.log("Form Data:", values);
  };

  const validationSchemas = [StepOneSchema, StepTwoSchema];

  return (
    <Box w="100%" mx="auto" p={6} borderRadius="16px" backgroundColor="#fff">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemas[activeStep - 1]}
        onSubmit={(values, actions) => {
          if (activeStep === 2) {
            handleSubmit(values);
          } else {
            handleNext(values, actions);
          }
        }}
      >
        {({ isValid }) => (
          <Form>
            <VStack spacing={4}>
              {activeStep === 1 && (
                <>
                  <Field name="formName">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.formName && form.touched.formName
                        }
                      >
                        <FormLabel>Form Name*</FormLabel>
                        <Input {...field} placeholder="Enter Form Name" />
                        <FormErrorMessage>
                          {form.errors.formName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </>
              )}

              {activeStep === 2 && (
                <>
                  {columns.map((column) => (
                    <Card w="100%" px="24px" py="12px" key={column}>
                      {column}
                    </Card>
                  ))}
                  <Button
                    style={{ marginRight: "auto" }}
                    variant="ghost"
                    px="8px"
                    fontWeight="medium"
                    rightIcon={showAddSection ? <Minus /> : <Add />}
                    onClick={() => setShowAddSection(!showAddSection)}
                  >
                    Add Another Section
                  </Button>
                  {showAddSection && (
                    <Field name="section">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.section && form.touched.section
                          }
                        >
                          <FormLabel>Section</FormLabel>
                          <Input {...field} placeholder="Enter Section" />
                          <FormErrorMessage>
                            {form.errors.section}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                </>
              )}
            </VStack>

            <Box mt={6} display="flex" justifyContent="space-between">
              <Button
                onClick={handleBack}
                variant="outline"
                borderColor="brand"
                size="sm"
              >
                {activeStep > 1 ? "Back" : "Cancel"}
              </Button>
              <Button type="submit" variant="brand" isDisabled={!isValid}>
                {activeStep === 1 ? "Save and Next" : "Submit"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default TwoStepForm;
