import {
  Box,
  Text,
  Grid,
  Heading,
  SimpleGrid,
  Skeleton,
} from "@chakra-ui/react";
import { useNavigate, NavLink } from "react-router-dom";
import cover_newproject from "assets/img/project/newproject.png";
import cover_help from "assets/img/dashboards/help.png";
import cover_insight from "assets/img/dashboards/insight.png";
import MiniStatistics from "components/card/MiniStatistics";
import Button from "components/fields/Button";
import ActionCard from "components/card/ActionCard";
import React from "react";
import {
  ClipboardText,
  ClipboardClose,
  ClipboardTick,
  Notification,
  LogoutCurve,
  DocumentLike,
  Chart21,
  Add,
} from "iconsax-react";

import PieCard from "views/admin/home/components/PieCard";
import TotalSpent from "views/admin/home/components/TotalSpent";
import WeeklyRevenue from "views/admin/home/components/WeeklyRevenue";
import Projects from "views/admin/home/components/Projects";
import { useGetAllProjects } from "services/projects/useGetAllProjects";
import SubscriptionBox from "components/payment/PaymentBox";
import { useUserPermission } from "hooks/useUserPermission";

export default function UserReports() {
  const navigate = useNavigate();
  const { data: projects, isLoading } = useGetAllProjects({ enabled: true });
  const isMobile = window.innerWidth < 768;
  const { canAddOrRemoveProject } = useUserPermission();

  const handleClick = () => {
    navigate("/onboarding/new-customer");
  };

  const activeRoute = (routeName) => location.pathname.includes(routeName);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bgColor={isMobile ? "#fff" : ""}
        px={{ base: "16px", md: "0" }}
        mb={{ base: "30px", md: "40px" }}
        h="48px"
      >
        <Heading
          as="h1"
          fontSize={{ base: "xl", md: "2xl" }}
          fontWeight="500"
          color={"#203764"}
        >
          Home
        </Heading>
        {!isMobile && canAddOrRemoveProject ? (
          <Button
            bgColor="#203764"
            textColor="#fff"
            leftIcon={<Add />}
            variant="brand"
            onClick={handleClick}
          >
            Create New Project
          </Button>
        ) : (
          <Box display="flex">
            <NavLink to="/admin/notification">
              <Box
                bgColor={activeRoute("notification") ? "#C5D2EC" : ""}
                height="30px"
                width="30px"
                borderRadius="15px"
                display="flex"
                mx="10px"
                justifyContent="center"
                alignItems="center"
              >
                <Notification color="#203764" />
              </Box>
            </NavLink>
            <NavLink to="/auth/sign-out">
              <Box
                bgColor={activeRoute("notification") ? "#C5D2EC" : ""}
                height="30px"
                width="30px"
                borderRadius="15px"
                mx="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <LogoutCurve color="#203764" />
              </Box>
            </NavLink>
          </Box>
        )}
      </Box>
      <Box mx={{ base: "20px", md: "0" }} hideFrom="xl">
        <SubscriptionBox />
      </Box>
      {isLoading ? (
        <Box mx={{ base: "20px", md: 0 }}>
          <SimpleGrid columns={{ base: 3, md: 2, lg: 3 }} gap="20px" mb="90px">
            <Skeleton height="70px" borderRadius={12} />
            <Skeleton height="70px" borderRadius={12} />
            <Skeleton height="70px" borderRadius={12} />
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mb="90px">
            <Skeleton height="160px" borderRadius={12} />
            <Skeleton height="160px" borderRadius={12} />
            <Skeleton height="160px" borderRadius={12} />
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mb="90px">
            <Skeleton height="160px" borderRadius={12} />
            <Skeleton height="160px" borderRadius={12} />
            <Skeleton height="160px" borderRadius={12} />
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mb="100px">
            <Skeleton height="300px" borderRadius={12} />
            <Skeleton height="300px" borderRadius={12} />
            <Skeleton height="300px" borderRadius={12} />
          </SimpleGrid>
        </Box>
      ) : (
        <Box mx={{ base: "20px", md: "0" }}>
          <SimpleGrid columns={{ base: 3, md: 2, lg: 3 }} gap="20px" mb="20px">
            <MiniStatistics
              name="All Projects"
              value={projects?.length}
              icon={<ClipboardText size="22" variant="Bold" color="#203764" />}
            />
            <MiniStatistics
              name="Incomplete Projects"
              value={
                projects?.filter((project) => project.status !== "Completed")
                  .length
              }
              icon={<ClipboardClose size="22" variant="Bold" color="#203764" />}
            />
            <MiniStatistics
              name="Generated Reports"
              value="0"
              icon={<ClipboardTick size="22" variant="Bold" color="#203764" />}
            />
          </SimpleGrid>

          {projects?.length === 0 ? (
            <Grid
              templateColumns={{
                base: "1fr",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={{ base: "16px", md: "20px" }}
              display="grid"
            >
              <ActionCard
                name="Add First Project"
                author="Create a project to start your inspection."
                bidders={[]}
                image={cover_newproject}
                currentbid="0.91 ETH"
                button_name="Create New Project"
                link="/onboarding/new-customer"
              />
              <ActionCard
                name="Learn how Repolet works"
                author="Discover how our service simplifies inspections."
                bidders={[]}
                image={cover_help}
                currentbid="0.91 ETH"
                button_name="Watch Video"
                link="/admin/new-project"
              />
              <ActionCard
                name="See Insights"
                author="Once you have more projects, insights will be displayed here."
                bidders={[]}
                image={cover_insight}
                currentbid="0.91 ETH"
                button_name=""
              />
            </Grid>
          ) : (
            <>
              <SimpleGrid>
                <Projects
                  title="Recently Updated"
                  projects={projects}
                  icon={<DocumentLike variant="Bold" />}
                />
              </SimpleGrid>
              <SimpleGrid>
                <Projects
                  title="Waiting for Quality Control"
                  projects={projects}
                  icon={<ClipboardTick variant="Bold" />}
                />
              </SimpleGrid>
              <Box>
                <Text
                  fontSize="md"
                  fontWeight="500"
                  my="20px"
                  display="flex"
                  alignItems="center"
                  gap={2}
                  color="primary.primary"
                >
                  <Chart21 variant="Bold" size={24} />
                  Insights
                </Text>
              </Box>

              <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mb="20px">
                <TotalSpent />
                <WeeklyRevenue />
                <PieCard />
              </SimpleGrid>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
