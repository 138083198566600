import { useUserRole } from "./useUserRole";

export const useUserPermission = () => {
  const { isCompanyAdmin, isCompanyExpert, isSuperAdmin } = useUserRole();

  const canAddOrRemoveCompany = isSuperAdmin;
  const canAddUsers = isCompanyAdmin || isSuperAdmin;
  const canAddOrRemoveCustomer = isCompanyAdmin || isSuperAdmin;
  const canAddOrRemoveProject = isCompanyAdmin || isSuperAdmin;
  const canCollectAndInputSiteInformation = isCompanyAdmin || isCompanyExpert;
  const canGenerateReports = isCompanyAdmin;
  const canAddNewRoles = isSuperAdmin;

  return {
    canAddOrRemoveCompany,
    canAddUsers,
    canAddOrRemoveCustomer,
    canAddOrRemoveProject,
    canCollectAndInputSiteInformation,
    canGenerateReports,
    canAddNewRoles,
  };
};
