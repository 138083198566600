import React from "react";
import { Formik, Field, Form } from "formik";
import {
  Box,
  Flex,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  InputGroup,
  Text,
  useColorModeValue,
  InputLeftAddon,
  VStack,
  Button,
  HStack,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import {
  expertValidationSchema,
  editExpertInitialValues,
} from "./validationSchema";
import illustration from "assets/img/auth/auth.png";
import useEditExpert from "services/experts/useEditExpert";
import { useDeleteExpert } from "./delete_expert/useDeleteExpert";
import DeleteExpert from "./delete_expert/delete_expert";
import { Arrow, ArrowLeft2, Trash } from "iconsax-react";

function EditExpert() {
  const textColor = useColorModeValue("navy.700", "white");
  const location = useLocation();
  const expert = location.state?.expert || {};
  const expertInitial = expert ? expert : editExpertInitialValues;
  const { colorMode } = useColorMode();
  const { mutate, isLoading } = useEditExpert();
  const {
    isOpen: isDeletePopupOpen,
    onOpen: openDeletePopup,
    onClose: onCloseDeletePopup,
    onDelete,
    isLoading: isDeleteLoading,
    error: deleteError,
  } = useDeleteExpert();

  const navigate = useNavigate();

  const handleCancelClick = () => {
    window.history.back();
  };

  const handleSubmit = (values) => {
    const payload = {
      id: expert?.id,
      ...values,
    };
    mutate(payload, {
      onSuccess: () => {
        navigate("/admin/experts");
      },
      onError: (e) => {
        console.error(e);
      },
    });
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        ml={{ base: "16px", lg: "50px" }}
        mt={{ base: "0px", lg: "50px" }}
        justifyContent={"space-between"}
        alignItems={"center"}
        color="primary.primary"
        h={{ base: "48px", md: "auto" }}
      >
        <Flex
          alignItems={"center"}
          onClick={handleCancelClick}
          cursor={"pointer"}
          zIndex={10}
          gap={3}
        >
          <ArrowLeft2 />
          <Text fontSize={"lg"} fontWeight="medium">
            Edit Expert
          </Text>
        </Flex>
        <Button onClick={openDeletePopup}>
          <Flex alignItems={"center"} justifyContent={"space-evenly"}>
            <Text fontWeight={100} fontSize={16} color={"red"} hideBelow={"md"}>
              Delete this Expert
            </Text>
            <Trash />
          </Flex>
        </Button>
      </Flex>

      <Flex
        mx="auto"
        me="auto"
        alignItems="center"
        justifyContent={{ base: "flex-start", md: "center" }}
        mb={{ base: "0", md: "60px" }}
        mt={{ base: "0", md: "7vh" }}
        flexDirection="column"
        background={{ base: "#F5F5F5", md: "white" }}
        w={{ base: "100%", md: "800px" }}
        h={{ base: "100%", md: "auto" }}
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", lg: "800px" }}
          maxW="100%"
          p={{ base: "16px", md: "30px" }}
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto" hideBelow={"md"}>
            <Text color={textColor} fontSize="28px" mb="10px">
              {expertInitial.first_name || "Edit Expert"}
            </Text>
          </Box>
          <Formik
            initialValues={expertInitial}
            validationSchema={expertValidationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <VStack spacing={3} alignItems="start">
                  <Flex width="100%" alignContent="space-between">
                    <HStack align="start" spacing={5} width="100%">
                      <Field name="first_name">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.first_name && form.touched.first_name
                            }
                            flex="1"
                          >
                            <FormLabel htmlFor="first_name" color={textColor}>
                              First Name *
                            </FormLabel>
                            <Input
                              {...field}
                              id="first_name"
                              placeholder="First Name"
                              borderRadius="10px"
                              backgroundColor="white"
                            />
                            <FormErrorMessage>
                              {form.errors.first_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="last_name">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.last_name && form.touched.last_name
                            }
                            flex="1"
                          >
                            <FormLabel htmlFor="last_name" color={textColor}>
                              Last Name *
                            </FormLabel>
                            <Input
                              {...field}
                              id="last_name"
                              placeholder="Last Name"
                              borderRadius="10px"
                              backgroundColor="white"
                            />
                            <FormErrorMessage>
                              {form.errors.last_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </HStack>
                  </Flex>
                  <Field name="phone">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.phone && form.touched.phone}
                      >
                        <FormLabel htmlFor="phone" color={textColor}>
                          Phone Number *
                        </FormLabel>
                        <InputGroup>
                          <InputLeftAddon>+1</InputLeftAddon>
                          <Input
                            {...field}
                            id="phone"
                            placeholder="(000) 000 0000"
                            type="tel"
                            maxLength={10}
                            borderRadius="10px"
                            backgroundColor="white"
                          />
                        </InputGroup>
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <FormLabel htmlFor="email" color={textColor}>
                          Email *
                        </FormLabel>
                        <Input
                          {...field}
                          id="email"
                          placeholder="ex: name@company.com"
                          borderRadius="10px"
                          backgroundColor="white"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </VStack>

                <HStack mt={10}>
                  <CButton
                    fontSize="base"
                    variant="brand"
                    fontWeight="500"
                    w="30%"
                    h="50"
                    type="button"
                    bgColor="#fff"
                    textColor="#203764"
                    border="solid .5px #203764"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </CButton>
                  <CButton
                    fontSize="base"
                    variant="brand"
                    fontWeight="500"
                    w="70%"
                    h="50"
                    type="submit"
                    isLoading={isLoading}
                    bgColor="#203764"
                    textColor="#fff"
                  >
                    Save Changes
                  </CButton>
                </HStack>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
      {isDeletePopupOpen && (
        <DeleteExpert
          isOpen={isDeletePopupOpen}
          onClose={onCloseDeletePopup}
          onDelete={() => onDelete(expert.id)}
          error={deleteError}
          expertName={expert.first_name + " " + expert.last_name}
          isLoading={isDeleteLoading}
        />
      )}
    </DefaultAuth>
  );
}

export default EditExpert;
