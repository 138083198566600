import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from "@chakra-ui/react";

const DeleteCustomer = ({ isOpen, onClose, onDelete, error, isLoading }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Customer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete this customer?
          {error && <Box color={"red"}>{error}</Box>}
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" size="lg" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="red"
            size="lg"
            onClick={onDelete}
            isLoading={isLoading}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteCustomer;
