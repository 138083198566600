/* eslint-disable */
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  FormHelperText,
  FormErrorMessage,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";

import { useAuth } from "contexts/AppContext";

// Assets
import illustration from "assets/img/auth/auth.png";
import { Eye, EyeSlash, Google } from "iconsax-react";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const errorMessage = "#F2415A";
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const { colorMode } = useColorMode();

  //------------------

  const { login } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    setIsDisabled(true);
    e.preventDefault();
    const success = await login(username, password);
    if (!success) {
      setIsDisabled(false);
      setError("Invalid username or password");
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    window.location.href = "https://api.repolet.dev/api/v1/auth/google/signin";
  };

  //------------------

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        // maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "458px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
          isDisabled={isDisabled}
          opacity={isDisabled ? "0.5" : "1"}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Sign In
            </Text>
          </Box>
          <form onSubmit={handleLogin}>
            <FormControl isInvalid={error ? true : false}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="username"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="ex: myname@example.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                borderColor={error ? errorMessage : null}
                value={username}
                id="username"
                name="username"
                onChange={(e) => setUsername(e.target.value)}
              />
              {!error ? null : (
                <FormErrorMessage mt="-18px" ml="10px">
                  {error}
                </FormErrorMessage>
              )}
              <FormLabel
                mt="30px"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                htmlFor="password"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="********"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  borderRadius="10px"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? Eye : EyeSlash}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    id="remember-login"
                    colorScheme="brandScheme"
                    me="10px"
                  />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="sm"
                  >
                    Remember me
                  </FormLabel>
                </FormControl>
                <NavLink to="/auth/forget-password">
                  <Text
                    color={textColorBrand}
                    fontSize="sm"
                    w="160px"
                    fontWeight="500"
                  >
                    I forget my password
                  </Text>
                </NavLink>
              </Flex>
              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                bgColor="#203764"
                textColor="#fff"
              >
                Sign In
              </CButton>
            </FormControl>
          </form>
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>
          <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            onClick={handleGoogleSignIn}
          >
            <Icon as={Google} w="20px" h="20px" me="10px" />
            Sign in with Google
          </Button>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="16px">
              Not registered yet?
              <NavLink to="/auth/sign-up">
                <Text color={textColor} as="span" ms="5px" fontWeight="bold">
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
