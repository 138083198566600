/* eslint-disable */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  Box,
  Flex,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  Text,
  useColorModeValue,
  VStack,
  Link,
  HStack,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import useInviteExperts from "services/experts/useInviteExperts";
import {
  inviteExpertInitialValues,
  inviteExpertSchema,
} from "./validationSchema";
import { UserRemove } from "iconsax-react";

function InviteExpert() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { colorMode } = useColorMode();
  const { mutate, isLoading } = useInviteExperts();
  const navigate = useNavigate();
  const location = useLocation();
  const hasParent = location.state?.hasParent;

  const handleInviteExperts = async (values) => {
    mutate(values, {
      onSuccess: () => {
        navigate("/admin/experts");
      },
      onError: (e) => {
        console.error(e);
      },
    });
  };

  return (
    <DefaultAuth>
      <Flex
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "558px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Invite Company Expert
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Invite Experts to your company to assign them to projects.
            </Text>
          </Box>

          <Formik
            initialValues={inviteExpertInitialValues}
            validationSchema={inviteExpertSchema}
            onSubmit={handleInviteExperts}
          >
            {() => (
              <Form>
                <VStack spacing={5} alignItems="start">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    htmlFor="emails"
                  >
                    Email<Text color={brandStars}>*</Text>
                  </FormLabel>

                  <FieldArray
                    name="emails"
                    render={(arrayHelpers) => (
                      <>
                        {arrayHelpers.form.values.emails.map((email, index) => (
                          <Flex key={index} width="100%">
                            <Field name={`emails.${index}`}>
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.emails &&
                                    form.errors.emails[index] &&
                                    form.touched.emails &&
                                    form.touched.emails[index]
                                  }
                                >
                                  <Input
                                    {...field}
                                    isRequired
                                    variant="auth"
                                    fontSize="sm"
                                    ms={{ base: "0px", md: "0px" }}
                                    type="email"
                                    placeholder="Add Email here"
                                    my="5px"
                                    fontWeight="500"
                                    w="90%"
                                    size="lg"
                                    borderRadius="10px"
                                  />
                                  <FormErrorMessage>
                                    {form.errors.emails &&
                                      form.errors.emails[index]}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <a
                              href="#!"
                              onClick={() => arrayHelpers.remove(index)}
                              style={{
                                color: "gray",
                                display: "block",
                                position: "relative",
                                marginLeft: "-30px",
                                marginTop: "12px",
                                fontSize: "24px",
                              }}
                            >
                              {arrayHelpers.form.values.emails.length > 1 ? (
                                <Icon
                                  as={UserRemove}
                                  width="20px"
                                  height="20px"
                                  color="inherit"
                                />
                              ) : null}
                            </a>
                          </Flex>
                        ))}
                        <Box pt="0" pb="30px" textAlign="center" pl="20px">
                          <Link onClick={() => arrayHelpers.push("")}>
                            <HStack>
                              <Text fontSize="30px">+</Text>
                              <Text> Add another</Text>
                            </HStack>
                          </Link>
                        </Box>
                      </>
                    )}
                  />
                </VStack>

                <CButton
                  fontSize="base"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  type="submit"
                  isLoading={isLoading}
                  bgColor="#203764"
                  textColor="#fff"
                >
                  Invite to your team
                </CButton>

                <Flex textAlign="center" w="100%">
                  {hasParent ? (
                    <Link
                      href="/admin/home"
                      fontSize="base"
                      variant="brand"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      mb="24px"
                      type="submit"
                      textColor="#203764"
                    >
                      Skip for now
                    </Link>
                  ) : (
                    <Link
                      href="/admin/experts"
                      fontSize="base"
                      variant="brand"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      mb="24px"
                      type="submit"
                      textColor="#203764"
                    >
                      Back
                    </Link>
                  )}
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default InviteExpert;
