import api from '../apiService'
import { useQuery } from '@tanstack/react-query'
import { API_URLS } from 'config/apiConfig'

export const getExpert = async ({ id }) => {
  const { data } = await api.get(`${API_URLS.exports}/${id}`)
 
  return data?.data || {}
}

export function useGetExpertById({ enabled, id}) {
  return useQuery({
    queryKey: ['expert'],
    enabled,
    queryFn: () => getExpert({ id }),
  })
}
