/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// Chakra imports
import {
  Box,
  Flex,
  useColorMode,
  Icon,
  Link,
  Text,
  useColorModeValue,
  VStack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  HStack,
} from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";

import { useAuth } from "contexts/AppContext";

// Assets
import illustration from "assets/img/auth/auth.png";
import Card from "components/card/Card";
import { ArrowLeft2 } from "iconsax-react";

function StartInspection() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const { colorMode } = useColorMode();

  //------------------

  const { getexperts } = useAuth();
  //
  const [experts, setExperts] = useState(null);
  const userState = useSelector((state) => state);
  const [selectedItem, setSelectedItem] = useState(null);
  const localState = useSelector((state) => state.local);

  const steps = [
    { title: "Customer Info", description: "" },
    { title: "Project Details", description: "" },
    { title: "Inspection", description: "" },
  ];

  const { activeStep } = useSteps({
    index: 2,
    count: steps.length,
  });

  useEffect(() => {
    setExperts(false);
    const getExperts = async (e) => {
      const experts_data = await getexperts();
      setExperts(experts_data);
      setSelectedItem(experts_data[0]);
      console.log(experts_data);
    };

    getExperts();
  }, []);

  const handleAddProject = async (e) => {
    // e.preventDefault();

    // const success = await addProject(
    //   project_address,
    //   project_name,
    //   selectedItem.value,
    //   "_description",
    //   "null",
    //   localState.objects.first_name,
    //   localState.objects.last_name,
    //   localState.objects.phone,
    //   localState.objects.email
    // );

    // if (!success) {
    //   setError("Error in add projejct");
    // }
    window.location = "/admin/projects";
  };

  const handleCancelClick = () => {
    window.location = "/admin/projects";
  };

  const handleSelectChange = (event) => {
    setSelectedItem(event.target.value); // Update the state with the selected value
  };

  //------------------

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        ma
        ml={{ base: "20px", lg: "50px" }}
        mt={{ base: "0px", lg: "50px" }}
        style={{ alignItems: "flex-end" }}
      >
        <Link href="/">
          <Icon
            as={ArrowLeft2}
            w="20px"
            h="20px"
            color="#000"
            marginRight={5}
          />
        </Link>

        <Text fontWeight={500} fontSize={22}>
          Add New Project
        </Text>
      </Flex>
      <Flex
        //  w={{ base: "100%", md: "800px" }}
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "0px", md: "0px" }}
        mt={{ base: "20px", md: "7vh" }}
        flexDirection="column"
      >
        <Card
          marginTop="0"
          mb="20px"
          w={{ base: "95%", md: "800px" }}
          mx={{ base: "10px", md: "60px" }}
        >
          <Stepper index={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <VStack>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                </VStack>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </Card>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "800px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Do you want to start the inspection process?
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Starting the Inspection phase will notify the assigned Expert and
              change the project status.
            </Text>
          </Box>
          <form onSubmit={handleAddProject}>
            <HStack>
              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="30%"
                h="50"
                type="button"
                bgColor="#fff"
                textColor="#203764"
                border="solid .5px #203764"
                onClick={handleCancelClick}
              >
                Not now
              </CButton>
              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="70%"
                h="50"
                type="submit"
                bgColor="#203764"
                textColor="#fff"
                onClick={handleAddProject}
              >
                Start Inspection
              </CButton>
            </HStack>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default StartInspection;
