import { HStack, Button, Text, Flex } from "@chakra-ui/react";

const Pagination = ({ currentPage, totalPages, onPageChange,onNextPage,onPrevPage }) => {
  return (
    <Flex justifyContent="center" mt={4}>
      <HStack spacing={2}>
        <Button
          onClick={onPrevPage}
          isDisabled={currentPage === 1}
        >
          Prev
        </Button>
        <Text>{`Page ${currentPage} of ${totalPages}`}</Text>
        <Button
          onClick={onNextPage}
          isDisabled={currentPage === totalPages}
        >
          Next
        </Button>
      </HStack>
    </Flex>
  );
};

export default Pagination;
