import api from "../apiService";
import { useQuery } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

export const getAllCustomers = async (page = 1, perPage = 10) => {
  const { data } = await api.get(
    `${API_URLS.customers}?page=${page}&per_page=${perPage}`
  );

  return data;
};

export function useGetAllCustomers({ enabled, page, perPage }) {
  return useQuery({
    queryKey: ["allCustomers", page, perPage],
    enabled,
    queryFn: () => getAllCustomers(page, perPage),
    keepPreviousData: true,
  });
}
