// src/config/apiConfig.js
export const API_BASE_URL = "https://api.repolet.dev/api/v1";

export const API_URLS = {
  login: `${API_BASE_URL}/login`,
  logout: `${API_BASE_URL}/logout`,
  register: `${API_BASE_URL}/register`,
  otp: `${API_BASE_URL}/verify-otp`,
  forgetPassword: `${API_BASE_URL}/password/forgot`,
  password_verify_otp: `${API_BASE_URL}/password/verify-otp`,
  setـnew: `${API_BASE_URL}/password/set-new`,
  onboarding: `${API_BASE_URL}/company/onboarding/info`,
  invite_expert: "/company/onboarding/invite-experts",
  signup_expert: `${API_BASE_URL}/expert/signup-with-invite`,
  exports: "/experts",
  expert: "/expert",
  get_user: `${API_BASE_URL}/user`,
  google_signup_callback: `${API_BASE_URL}/auth/google/signup/callback`,
  google_signin_callback: `${API_BASE_URL}/auth/google/signin/callback`,
  company_experts: `${API_BASE_URL}/experts`,
  add_projects: `${API_BASE_URL}/projects`,
  edit_projects: `${API_BASE_URL}/projects/`,
  all_projects: `${API_BASE_URL}/projects`,
  all_customers: `${API_BASE_URL}/customers`,
  customer: `${API_BASE_URL}/customer/`,
  customers: "/customers",
  edit_profile: `${API_BASE_URL}/company/profile`,
  payment_method: '/company/payments',
  billing_info: '/company/billing-info',
  states: '/location/states',
  cities: '/location/cities',
};
