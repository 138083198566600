import * as Yup from "yup";
import dayjs from "dayjs";

export const billingAddressValidationSchema = Yup.object().shape({
    card_number: Yup.string()
        .matches(/^\d{16}$/, "Card number must be 16 digits")
        .required("Card number is required"),
    expiration_date: Yup.string()
        .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, "Expiration date must be in MM/YY format")
        .test("expiration_date", "Expiration date must be in the future", (value) => {
            if (!value) return false;
            const [month, year] = value.split("/").map(Number);
            const expiryDate = dayjs().set("year", 2000 + year).set("month", month - 1).endOf("month");
            return expiryDate.isAfter(dayjs());
        })
        .required("Expiration date is required"),
    cvv2: Yup.string()
        .matches(/^\d{3}$/, "CVV must be 3 digits")
        .required("CVV is required"),
    card_holder_name: Yup.string().required("Cardholder name is required"),
    company_name: Yup.string().required("Company name is required"),
    country: Yup.string().required("Country is required"),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip_or_postal_code: Yup.string().required("ZIP/Postal Code is required"),
});

export const paymentInitialValues = {
  card_number: "",
  expiration_date: "",
  cvv2: "",
  card_holder_name: "",
  company_name: "",
  country: "",
  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  zip_or_postal_code: "",
}
