/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";
import {
  Box,
  Center,
  Flex,
  useColorMode,
  HStack,
  Text,
  useColorModeValue,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import { useAuth } from "contexts/AppContext";
import illustration from "assets/img/auth/auth.png";

function OTP() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const { colorMode } = useColorMode();

  //------------------

  const { otp_verify, otp_verify_newPassword } = useAuth();

  const [error, setError] = useState("");

  const handleOtp = async (e) => {
    e.preventDefault();
    if (mode == "3droWssAp_wEn") {
      const success = await otp_verify_newPassword(pin, email);
      if (!success) {
        setError("Invalid OTP");
      }
    } else {
      const success = await otp_verify(pin, email);
      if (!success) {
        setError("Invalid OTP");
      }
    }
  };

  //------------------

  const [pin, setPin] = useState("");

  const [timeLeft, setTimeLeft] = useState(120); // 120 seconds = 2 minutes
  const [isTimeUp, setIsTimeUp] = useState(false);

  const isPinComplete = pin.length === 5;

  const userState = useSelector((state) => state.auth);
  const email = userState.user?.username;
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("md");

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else {
      setIsTimeUp(true); // Set time-up state when time reaches 0
    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        // maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Center></Center>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "458px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Verification Code
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              We’ve sent a verification code to your{" "}
              <Text fontWeight="bold" color="black">
                {email}
              </Text>
            </Text>
          </Box>
          <form onSubmit={handleOtp}>
            <Flex my={5} alignItems="center" justifyContent="center">
              <HStack>
                <PinInput value={pin} onChange={setPin}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Flex>

            <Flex justifyContent="space-evenly" align="center" mb="24px">
              <Text>Time Left: {formatTime(timeLeft)}</Text>
            </Flex>
            <CButton
              isDisabled={!isPinComplete || isTimeUp}
              fontSize="base"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              type="submit"
              bgColor="#203764"
              textColor="#fff"
            >
              Verify
            </CButton>
          </form>
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="900" fontSize="16px">
              Email was not correct?
              <NavLink to="/auth/sign-in">
                <Text color="blue" as="span" ms="5px" fontWeight="900">
                  Edit Email
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default OTP;
