import * as Yup from "yup";

export const customerValidationSchema = (isDisabled)=>{
  return Yup.object({
    first_name: isDisabled
    ? Yup.string().nullable()
    : Yup.string().required("First name is required"),
    last_name:  isDisabled
    ? Yup.string().nullable()
    : Yup.string().required("Last name is required"),
    phone: isDisabled
    ? Yup.string().nullable()
    :Yup.string()
      .matches(/^[0-9]*$/, "Phone number can only contain numbers")
      .length(10, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
    email: isDisabled
    ? Yup.string().nullable()
    :Yup.string().email("Invalid email address").required("Email is required"),
  })
};

export const customerInitialValues = {
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
};
