/* eslint-disable */
import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

// Chakra imports
import {
  Box,
  Flex,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  InputGroup,
  Link,
  Text,
  useColorModeValue,
  InputLeftAddon,
  VStack,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import { useAuth } from "contexts/AppContext";
import illustration from "assets/img/auth/auth.png";
import { Image } from "iconsax-react";

function AddCompany() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { colorMode } = useColorMode();
  const { onboarding } = useAuth();
  const [company_phone, setCompany_phone] = useState("");
  const [company_address, setCompany_address] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [base64Image, setBase64Image] = useState("");
  // next refactor
  const [error, setError] = useState("");
  const inputFileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleOnboarding = async (e) => {
    e.preventDefault();
    var _description = "description";
    const success = await onboarding(
      company_name,
      company_phone,
      company_address,
      selectedFile,
      _description
    );

    if (!success) {
      setError("Invalid username or password");
    }
  };

  const handleFileChange = (e) => {
    var selectedFile = e.target.files[0];
    setSelectedFile(selectedFile);
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result); // Set the base64 string as the image source
    };
    if (selectedFile) {
      reader.readAsDataURL(selectedFile); // Read the image file as a data URL (base64)
    }
  };

  const handleClick = () => {
    inputFileRef.current.click(); // Trigger the hidden file input click
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        // maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "0px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "558px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Create Company Profile
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Create a company profile to add your first project.
            </Text>
          </Box>
          <form onSubmit={handleOnboarding}>
            <VStack spacing={4} alignItems="start">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="0px"
                htmlFor="company_name"
              >
                Company Name <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter the company name"
                mb="24px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                value={company_name}
                id="company_name"
                name="company_name"
                onChange={(e) => setCompany_name(e.target.value)}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="0px"
                htmlFor="company_address"
              >
                Company Address <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter the company address"
                mb="24px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                value={company_address}
                id="company_address"
                name="company_address"
                onChange={(e) => setCompany_address(e.target.value)}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="username"
              >
                Primary Mobile Number <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>+1</InputLeftAddon>
                <Input
                  type="tel"
                  placeholder="phone number"
                  value={company_phone}
                  id="company_phone"
                  isRequired={true}
                  name="company_phone"
                  onChange={(e) => setCompany_phone(e.target.value)}
                />
              </InputGroup>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="username"
              >
                Company Logo (Optional)
              </FormLabel>
              <Flex justifyContent="space-between" pb="30px" width="100%">
                <Box
                  borderRadius="20px"
                  border=".5px #718096 solid"
                  height="100px"
                  width="100px"
                  mb="20px"
                  mr="15px"
                  align="center"
                >
                  {!base64Image ? (
                    <Icon
                      as={Image}
                      w="40px"
                      h="40px"
                      color="#718096"
                      mt="30px"
                    />
                  ) : (
                    <img style={{ borderRadius: "20px" }} src={base64Image} />
                  )}
                </Box>

                <VStack alignItems="start" spacing={1} mt="20px">
                  <Text fontSize="12px">The file might be png or jpg </Text>
                  <Text fontSize="12px">
                    The image size shoudn’t be more than 124kb
                  </Text>
                </VStack>
                <Link
                  style={{
                    border: ".5px solid #8e9ca8",
                    borderRadius: "8px",
                    textAlign: "center",
                    display: "block",
                    width: "100px",
                    height: "100px",
                    paddingTop: "33px",
                    marginLeft: "25px",
                    backgroundColor: "#ebf2ff",
                  }}
                  onClick={handleClick}
                >
                  Upload
                </Link>
              </Flex>
              <input
                accept="image/*"
                ref={inputFileRef}
                style={{ display: "none" }}
                type="file"
                onChange={handleFileChange}
              />
            </VStack>

            <CButton
              fontSize="base"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              type="submit"
              bgColor="#203764"
              textColor="#fff"
            >
              Submit and continue
            </CButton>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default AddCompany;
