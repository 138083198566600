import React from "react";
import {
    Card,
    CardBody,
    Text,
    VStack,
    useTheme,
    HStack,
    Divider,
} from "@chakra-ui/react";

const TotalCard = ({Subtotal='39.99',Tax='0',total='39.99' }) => {
    const theme = useTheme();
    return (
        <Card
            borderRadius="20px"
            bg={theme.colors.surface.white}
            boxShadow='base'
            width={{ base: "100%", lg: "374px" }}
            px={{ base: "24px", md: "32px" }}
            py={{ base: "24px", md: "40px" }}
        >
            <CardBody p="0">
                <VStack align="start" spacing={2} color={'primary.onPrimary'}>
                    <HStack justify="space-between" width="100%">
                        <Text fontSize={'md'} fontWeight={'bold'}>Subtotal</Text>
                        <Text fontSize={'md'}>{Subtotal} $</Text>
                    </HStack>
                    <Divider />
                    <HStack justify="space-between" width="100%">
                        <Text fontSize={'md'} fontWeight={'bold'}>Tax</Text>
                        <Text fontSize={'md'}>{Tax} $</Text>
                    </HStack>
                    <Divider />
                    <HStack justify="space-between" width="100%">
                        <Text fontSize={'md'} fontWeight={'bold'}>Total due today</Text>
                        <Text fontSize={'md'}>{total} $</Text>
                    </HStack>
                </VStack>
            </CardBody>
        </Card>
    );
};

export default TotalCard;
