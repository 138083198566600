import api from '../apiService'
import { useQuery } from '@tanstack/react-query'
import { API_URLS } from 'config/apiConfig'

export const getCities = async ({ cityId }) => {
  const { data } = await api.get(`${API_URLS.cities}/${cityId}`)
 
  return data?.data || {}
}

export function useGetCities({ enabled, cityId}) {
  return useQuery({
    queryKey: ['cities', cityId],
    enabled,
    queryFn: () => getCities({ cityId }),
  })
}

export const getStates = async ({ countryName }) => {
    const { data } = await api.get(`${API_URLS.states}/${countryName}`)
   
    return data?.data || {}
  }
  
  export function useGetStates({ enabled, countryName}) {
    return useQuery({
      queryKey: ['states', countryName],
      enabled,
      queryFn: () => getStates({ countryName }),
    })
  }
  
