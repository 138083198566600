import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Button,
} from "@chakra-ui/react";

const DeleteProject = ({
  isOpen,
  onClose,
  onDelete,
  error,
  isLoading,
  projectName,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md" color="text.title">
          Delete the Project
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="md">
            Are you sure you want to delete <strong>{projectName}</strong>?
          </Text>
          <Text>
            This will also delete all the data and the reports tied to the
            project. This action cannot be reversed.
          </Text>
          {error && <Box color={"red"}>{error}</Box>}
        </ModalBody>

        <ModalFooter>
          <Button
            variant="outline"
            size="lg"
            mr={3}
            onClick={onClose}
            w={"100%"}
          >
            No
          </Button>
          <Button
            variant={"brand"}
            size="lg"
            onClick={onDelete}
            isLoading={isLoading}
            w={"100%"}
          >
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteProject;
