import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  SimpleGrid,
  Text,
  Flex,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import Button from "components/fields/Button";
import { useAuth } from "contexts/AppContext";
import Card from "components/card/Card";
import Projects from "views/admin/home/components/Projects";
import {
  ArrowLeft2,
  Call,
  DocumentText,
  Edit,
  ProfileCircle,
  Sms,
} from "iconsax-react";
import { useGetAllCustomers } from "services/customers/useGetAllCustomers";

export default function CustomerDetails() {
  const { getCustomers } = useAuth();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [customer, setCustomer] = useState([]);
  const localState = useSelector((state) => state.local);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US"); // Format to MM/DD/YYYY
  };
  const { data } = useGetAllCustomers({
    enabled: true,
  });

  useEffect(() => {
    const customers = data?.data || [];
    var customerId = new URLSearchParams(window.location.search).get(
      "customerId"
    );
    // var customers = localState.objects;
    var filter_customers = customers.filter(
      (customer) => customer.id == customerId
    );
    if (filter_customers)
      if (filter_customers.length > 0) {
        setCustomer(filter_customers[0]);
        setProjects(filter_customers[0].projects);
      }
  }, [data]);

  const handleCreateNewProject = () => {
    navigate("/onboarding/new-customer", { state: { hasParent: true } });
  };

  const handleEditCustomer = () => {
    navigate("/onboarding/edit-customer?customer-id=" + customer.id); // internal route
  };

  return (
    <Box pt={{ md: "30px", xl: "30px" }} color="primary.primary">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor={{ base: "#fff", md: "transparent" }}
        px={{ base: "16px", md: "0" }}
        h="48px"
        mb={4}
      >
        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="500"
          display="flex"
          alignItems="center"
          gap={{ base: 1, md: 4 }}
          cursor="pointer"
          onClick={() => navigate("/admin/customers")}
        >
          <ArrowLeft2 fontSize={24} />
          Customer Details
        </Heading>
        <Box hideFrom={"md"}>
          <Edit size={24} onClick={handleEditCustomer} />
        </Box>
        {/* <Button
          bgColor="#203764"
          textColor="#fff"
          leftIcon={<MdAdd />}
          variant="brand"
          onClick={handleCreateNewProject}
        >
          Add New Customer
        </Button> */}
      </Box>

      <Flex
        gap={2}
        fontSize={"18px"}
        alignItems={"center"}
        hideFrom={"md"}
        mx={4}
      >
        <ProfileCircle variant="Bold" size={24} />
        <Text fontWeight="medium" fontSize="xl">
          {customer.first_name} {customer.last_name}
        </Text>
      </Flex>

      <Card
        mt={{ base: 4, md: "50px" }}
        mx={4}
        width="auto"
        py={{ base: 0, md: 8 }}
      >
        <Flex
          display="flex"
          justifyContent="space-between"
          alignItems={{ base: "initial", md: "center" }}
          direction={{ base: "column", md: "row" }}
        >
          <Flex
            gap={1}
            fontSize={"16px"}
            alignItems={"center"}
            hideBelow={"md"}
          >
            <ProfileCircle variant="Bold" color="#203764" size={20} />
            <Text fontWeight={"bold"}>
              {customer.first_name} {customer.last_name}
            </Text>
          </Flex>
          <Flex fontSize={"16px"} gap={4} hideFrom={"md"} py={3}>
            <Flex alignItems={"center"} gap={1} width="72px">
              <Call variant="Bold" color="#203764" size={20} />
              <Text fontWeight={"medium"}>Phone </Text>
            </Flex>
            :<Text>{customer.phone}</Text>
          </Flex>
          <Flex fontSize={"16px"} gap={4} py={3}>
            <Flex alignItems={"center"} gap={1} width="72px">
              <Sms variant="Bold" color="#203764" size={20} />
              <Text fontWeight={{ base: "medium", md: "bold" }}>Email </Text>
            </Flex>
            :<Text>{customer.email}</Text>
          </Flex>
          <Flex fontSize={"16px"} gap={4} hideBelow={"md"}>
            <Flex alignItems={"center"} gap={1}>
              <Call variant="Bold" color="#203764" size={20} />
              <Text fontWeight={"bold"}>Phone Number </Text>
            </Flex>
            :<Text>{customer.phone}</Text>
          </Flex>
          <Box fontSize={"16px"} hideBelow={"md"}>
            <Button
              onClick={handleEditCustomer}
              variant="outline"
              border="1px"
              px={12}
              py={5}
            >
              Edit
            </Button>
          </Box>
        </Flex>
      </Card>

      <SimpleGrid mx={4}>
        <Projects
          title={"Project List"}
          TitleIcon={<DocumentText variant="Bold" size={24} />}
          gridArea="1 / 2 / 2 / 2"
          projects={projects}
        />
      </SimpleGrid>
    </Box>
  );
}
