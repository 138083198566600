import api from "../apiService";
import { useQuery } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

export const getAllProjects = async (page = 1, perPage = 10) => {
  const { data } = await api.get(`${API_URLS.all_projects}`);

  return data.data.projects;
};

export function useGetAllProjects({ enabled, page, perPage }) {
  return useQuery({
    queryKey: ["allProjects", page, perPage],
    enabled,
    queryFn: () => getAllProjects(page, perPage),
    keepPreviousData: true,
  });
}
