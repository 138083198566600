import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import userReducer from 'store/userReducer';
import localReducer from "store/localReducer";

const persistConfig = {
  key: "root",
  storage, 
  // whitelist: ["user"], 
};
const persistLocalConfig = {
  key: "local",
  storage,
  // whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, userReducer);
const persistedLocalReducer = persistReducer(persistLocalConfig, localReducer);

const store = configureStore({
  reducer: {
    auth: persistedReducer,
    local: persistedLocalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };

