// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  Box,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";

export default function Default(props) {
  const { name, value, icon, loading } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.800";
  const cardBg = "#E4EAF6";
  const loadingBg = "#e4e4e4";
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
    <Card py="15px" backgroundColor={cardBg} opacity={loading ? ".3" : "1"}>
      <Flex
        my="auto"
        h="100%"
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
        textAlign={{ base: "center", md: "left" }}
        px={{ base: "5px", md: "5px" }}
      >
        <Box
          mt={{ base: "10px", md: "0" }}
          display="flex"
          justifyContent="start"
          alignItems="center"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box
            bgColor={boxBg}
            h="45px"
            w="45px"
            borderRadius="50%"
            mr={{ md: "10px" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {icon}
          </Box>
          <Text
            color="primary.primary"
            fontWeight={{ base: "500" }}
            lineHeight={{ base: "20px" }}
            fontSize={{
              base: "sm",
              md: "md",
            }}
            mt={{ base: "10px", md: "0" }}
          >
            {name}
          </Text>
        </Box>
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColor}
            fontWeight="bold"
            fontSize={{
              base: "md",
              md: "xl",
            }}
          >
            {value}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
}
