// src/services/userService.js
import { API_URLS } from "config/apiConfig";

export const SignIn_service = async (username, password) => {
  try {
    const _body = JSON.stringify({
      email: username,
      password: password,
    });

    const response = await fetch(API_URLS.login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
      },
      body: _body,
    });
    console.log(response.body);

    if (!response.ok) {
      throw new Error("Login failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Login error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const forgetPassword_service = async (username) => {
  try {
    const _body = JSON.stringify({
      email: username,
    });

    const response = await fetch(API_URLS.forgetPassword, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
      },
      body: _body,
    });
    console.log(response.body);

    if (!response.ok) {
      throw new Error("Login failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Login error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const verifyOTP_service = async (otp, email) => {
  try {
    const _body = JSON.stringify({
      email: email,
      otp: otp,
    });

    const response = await fetch(API_URLS.otp, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
      },
      body: _body,
    });

    if (!response.ok) {
      throw new Error("OTP failed");
    }

    const data = await response.json();
    console.log(data);
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Login error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const verifyPasswordOTP_service = async (otp, email) => {
  try {
    const _body = JSON.stringify({
      email: email,
      otp: otp,
    });

    const response = await fetch(API_URLS.password_verify_otp, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
      },
      body: _body,
    });

    if (!response.ok) {
      throw new Error("OTP failed");
    }

    const data = await response.json();
    console.log(data);
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Login error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const changePassword_service = async (email, otp, password, confirm_password) => {
  try {
    const _body = JSON.stringify({
      email: email,
      otp: otp,
      password: password,
      password_confirmation: confirm_password,
    });

    const response = await fetch(API_URLS.setـnew, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
      },
      body: _body,
    });

    if (!response.ok) {
      throw new Error("Password set failed");
    }

    const data = await response.json();
    console.log(data);
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Password set error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const signupExpert_service = async (
  username,
  name,
  password,
  token,
) => {
  try {
    const _body = JSON.stringify({
      token: token,
      email: username,
      name: name,
      password: password,
      password_confirmation: password,
    });

    const response = await fetch(API_URLS.signup_expert, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
      },
      body: _body,
    });
    console.log(response.body);

    if (!response.ok) {
      throw new Error("Register expert failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Register expert error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const SignUp_service = async (username, password, name, company_name) => {
  try {
    const _body = JSON.stringify({
      name: username,
      email: username,
      password: password,
      password_confirmation: password,
      company_name: company_name,
    });

    const response = await fetch(API_URLS.register, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
      },
      body: _body,
    });
    console.log(response.body);

    if (!response.ok) {
      throw new Error("Register failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Register error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const getUser_service = async (token) => {
  try {
    const response = await fetch(API_URLS.get_user, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
        
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user information");
    }

    const data = await response.json();
    return data; // Returns the user info from the API response
  } catch (error) {
    console.error("Get user error:", error);
    throw error; // Re-throw error to be handled where this function is called
  }
};

export const signInGoogle_service = async (code) => {
   try {
     const response = await fetch(API_URLS.google_signin_callback, {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify({ code }),
     });

     if (!response.ok) {
       throw new Error("Network response was not ok");
     }

     const data = await response.json();
     const token = data.token;

     return data;
   } catch (error) {
     console.error("Error during Google sign-in callback:", error);
     // Handle the error (e.g., redirect to login or show an error message)
     return false;
   }
 };

 export const signUpGoogle_service = async (code) => {
   try {
     const response = await fetch(API_URLS.google_signup_callback, 
       {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
         },
         body: JSON.stringify({ code: code }),
       }
     );

     if (!response.ok) {
       throw new Error("Network response was not ok");
     }

     const data = await response.json();
     const token = data.token;

     return data;
   } catch (error) {
     console.error("Error during Google sign-in callback:", error);
     // Handle the error (e.g., redirect to login or show an error message)
     return false;
   }
 };
