import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import {
  ArrowLeft2,
  ArrowRight2,
  Copy,
  DocumentCopy,
  Setting,
  Setting2,
} from "iconsax-react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "components/fields/Button";
import Columns from "./components/Columns";
import Question from "./components/Question";

const FormDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  return (
    <Box color="primary.primary">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        px={{ base: "16px", md: 0 }}
        backgroundColor={{ base: "#fff", md: "transparent" }}
        h="48px"
      >
        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="500"
          display="flex"
          alignItems="center"
          gap={1}
          cursor="pointer"
          onClick={() => navigate("/admin/forms")}
        >
          <ArrowLeft2 fontSize={24} />
          Form Details - {id}
        </Heading>
        <Stack direction="row">
          <Button
            variant="outline"
            border="1px"
            fontWeight="medium"
            px="24px"
            rightIcon={<DocumentCopy />}
          >
            Duplicate This Form
          </Button>
          <Button
            bgColor="#203764"
            textColor="#fff"
            rightIcon={<Setting2 />}
            variant="brand"
            hideBelow="md"
            border="1px solid transparent"
            fontWeight="medium"
            px="24px"
          >
            Form Settings
          </Button>
        </Stack>
      </Flex>

      <Columns />
      <Question number="1" title="Can you see Cracks/ Gaps on the surface?" />
      <Question number="2" title="Can you see Cracks/ Gaps on the surface?" />
      <Question number="3" title="Can you see Cracks/ Gaps on the surface?" />
      <Question number="4" title="Can you see Cracks/ Gaps on the surface?" />
      <Question number="5" title="Can you see Cracks/ Gaps on the surface?" />
    </Box>
  );
};

export default FormDetails;
