import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HorizonLogoSmall } from "components/icons/Icons";
// import { HSeparator } from "components/separator/Separator";

export function SidebarBrand({ isSidebarExpanded }) {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column">
      {isSidebarExpanded ? (
        <HorizonLogo
          h="56px"
          w="230px"
          mt="30px"
          ml="70px"
          mb="20px"
          color={logoColor}
        />
      ) : (
        <HorizonLogoSmall h="32px" w="32px" mt="40px" mb="20px" />
      )}
      {/* <HSeparator mb='20px' /> */}
    </Flex>
  );
}

export default SidebarBrand;
