// Action to log in
export const loginSuccess = (user) => {
  return {
    type: "LOGIN_SUCCESS",
    payload: {
      username: user.username,
      token: user.token,
      isAuthenticated: user.isAuthenticated,
    },
  };
};

// Action to log out
export const userInfo = (user, token) => { 
  if (token) {
    localStorage.setItem("userToken", token);
  }
  return {
    type: "USER_INFO",
    payload: {
      company: user.company,
      roles: user.roles,
      user: user.user,
      token: token,
    },
  };
};

// Action to log out
export const logoutDone = () => {
  localStorage.removeItem("userToken");
  return {
    type: "LOGOUT",
    payload: null,
  };
};

export const setUser = (user) => {
  return {
    type: "SET_USER",
      payload: {
        username: user.username,
        company: user.company,
        token: user.token,
        otp: user.otp,
        isAuthenticated: user.isAuthenticated
      },
  };
};
