import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "contexts/AppContext";

function GoogleSigninCallback() {
  const navigate = useNavigate();
  const { login_google, register_google } = useAuth();

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("t");
    const status = new URLSearchParams(window.location.search).get("s");
    const flow = new URLSearchParams(window.location.search).get("f");
    const error = new URLSearchParams(window.location.search).get("e");

 

    if (flow == "in") {
      if (status == "t") {
        login_google(token);
      }
      if (status == "f") {
        navigate("/auth/sign-in");
      }
    } else if (flow == "up") {
      if (status == "t") {
        register_google(token);
      }
      if (status == "f" && error == "409") {
        alert("user exist!");
        navigate("/auth/sign-in");
      }
    }
  }, [navigate]);

  return (
    <div style={{ margin: "0 auto", width: "100px", marginTop: "200px" }}>
      Redirecting ...
    </div>
  );
}

export default GoogleSigninCallback;
