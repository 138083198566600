import { API_URLS } from "config/apiConfig";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const deleteCustomer = async (customerId, token) => {
  const response = await fetch(API_URLS.customer + customerId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to delete customer");
  }

  return response.json();
};

export const useDeleteCustomer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const userState = useSelector((state) => state.auth);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setError(null);
  };

  const onDelete = async (customerId) => {
    setIsLoading(true);
    setError(null);

    try {
      await deleteCustomer(customerId, userState.user.token);
      setIsLoading(false);
      onClose();
      navigate("/admin/customers");
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  return { isOpen, onOpen, onClose, onDelete, isLoading, error };
};
