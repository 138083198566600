import React from "react";
import { Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import PaymentLayout from "layouts/payment";
import PlanCard from "components/payment/PlanCard";
import TotalCard from "components/payment/TotalCard";
import PaymentForm from "components/payment/paymentForm";

function PaymentDetails() {
  const location = useLocation();
  const planData = location.state || {}; 

  return (
    <PaymentLayout backText="Payment Details" hasBackButton>
      <Flex justifyContent={'center'} flexDirection={{ base: 'column-reverse', lg: 'row' }} flexWrap="wrap" gap={'6'} mt={10}>
        <Flex flex={{ base: 1, lg: 2 }} mb={{ base: 10, lg: 0 }} direction={'column'} alignItems={{ base: 'center', lg: 'end' }}>
          <PaymentForm />
        </Flex>

        <Flex flex="1" direction={'column'} gap={'6'} mb={4} alignItems={{ base: 'center', lg: "flex-start" }}>
          <PlanCard {...planData} minimized />
          <TotalCard Subtotal={planData?.price || ''} total={planData?.price || ''}/>
        </Flex>
      </Flex>
    </PaymentLayout>
  );
}

export default PaymentDetails;
