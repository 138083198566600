import { useSelector } from "react-redux";

export const useUserRole = () => {
  const userState = useSelector((state) => state.auth);
  const roles = userState.user.roles;

  const isCompanyAdmin = roles.includes("companyAdmin");
  const isCompanyExpert = roles.includes("expert");
  const isSuperAdmin = roles.includes("superAdmin");

  return { isCompanyAdmin, isCompanyExpert, isSuperAdmin };
};
