import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import {
    Flex,
    VStack,
    HStack,
    useColorModeValue,
    Text,
    Card,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Checkbox,
    Select,
    Box,
} from "@chakra-ui/react";
import CButton from "components/fields/Button";
import { billingAddressValidationSchema, paymentInitialValues } from "./validationSchema";
import useAddBillingInfo from "services/payment/useAddBillingInfo";
import useAddPaymentMethod from "services/payment/useAddPaymentMethod";
import { useGetCities, useGetStates } from "services/location";

function PaymentForm() {
    const [isChecked, setIsChecked] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const { mutateAsync: mutateBilling, isLoading: isBillingLoading } = useAddBillingInfo();
    const { mutateAsync: mutatePayment, isLoading: isPaymentLoading } = useAddPaymentMethod();
    const textColor = useColorModeValue('navy.700', 'white');

    const { data: statesData, isLoading: isStatesLoading } = useGetStates({
        enabled: !!selectedCountry,
        countryName: selectedCountry,
    });

    const { data: citiesData, isLoading: isCitiesLoading } = useGetCities({
        enabled: !!selectedState,
        cityId: selectedState,
    });

    const handleSubmit = async (values) => {
        const { card_number, expiration_date, cvv2, card_holder_name, ...rests } = values || {};
        try {
            const [billingResponse, paymentResponse] = await Promise.all([
                mutateBilling(rests),
                mutatePayment({ card_number, expiration_date, cvv2, card_holder_name }),
            ]);
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    return (
        <Card p={8} borderRadius="20px" shadow="md" mb={8} width={{ base: '100%', lg: '90%' }}>
            <Text fontSize="28px" mb={4}>
                Payment Method
            </Text>
            <Formik
                initialValues={paymentInitialValues}
                validationSchema={billingAddressValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, isSubmitting }) => (
                    <Form>
                        <VStack spacing={4} align="start">
                        <Field name="card_number">
                                {({ field, form }) => (
                                    <FormControl
                                        isInvalid={form.errors.card_number && form.touched.card_number}
                                    >
                                        <FormLabel htmlFor="card_number" color={textColor}>
                                            Card Number *
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            id="card_number"
                                            placeholder="1234 1234 1234 1234"
                                            type="text"
                                            maxLength={16}
                                        />
                                        <FormErrorMessage>{form.errors.card_number}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Flex width="100%" alignContent="space-between">
                                <HStack align="start" spacing={5} width="100%">
                                    <Field name="expiration_date">
                                        {({ field, form }) => (
                                            <FormControl
                                                isInvalid={
                                                    form.errors.expiration_date && form.touched.expiration_date
                                                }
                                                flex="1"
                                            >
                                                <FormLabel htmlFor="expiration_date" color={textColor}>
                                                    Expiration Date *
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    id="expiration_date"
                                                    placeholder="MM/YY"
                                                />
                                                <FormErrorMessage>
                                                    {form.errors.expiration_date}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="cvv2">
                                        {({ field, form }) => (
                                            <FormControl
                                                isInvalid={form.errors.cvv2 && form.touched.cvv2}
                                                flex="1"
                                            >
                                                <FormLabel htmlFor="cvv2" color={textColor}>
                                                    CVV2 *
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    id="cvv2"
                                                    placeholder="123"
                                                    type="text"
                                                />
                                                <FormErrorMessage>
                                                    {form.errors.cvv2}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </HStack>
                            </Flex>
                            <Field name="card_holder_name">
                                {({ field, form }) => (
                                    <FormControl
                                        isInvalid={
                                            form.errors.card_holder_name && form.touched.card_holder_name
                                        }
                                    >
                                        <FormLabel htmlFor="card_holder_name" color={textColor}>
                                            Card Holder Name *
                                        </FormLabel>
                                        <Input {...field} id="card_holder_name" placeholder="Full name on card" />
                                        <FormErrorMessage>{form.errors.card_holder_name}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Box pt={8} mb={4}>
                                <Text fontSize="28px" lineHeight="40px">
                                    Billing Address
                                </Text>
                            </Box>
                            <Field name="company_name">
                                {({ field }) => (
                                    <FormControl>
                                        <FormLabel htmlFor="company_name" color={textColor}>
                                            Company Name *
                                        </FormLabel>
                                        <Input {...field} id="company_name" />
                                    </FormControl>
                                )}
                            </Field>                            
                            <Field name="country">
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.country && form.touched.country}>
                                        <FormLabel htmlFor="country" color={textColor}>
                                            Country *
                                        </FormLabel>
                                        <Select
                                            {...field}
                                            id="country"
                                            placeholder="Choose your country"
                                            onChange={(e) => {
                                                const country = e.target.value;
                                                setFieldValue("country", country);
                                                setFieldValue("state", "");
                                                setFieldValue("city", "");
                                                setSelectedCountry(country);
                                                setSelectedState(null);
                                            }}
                                        >
                                            <option value="US">United States</option>
                                            {/* <option value="CA">Canada</option> */}
                                        </Select>
                                        <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="address_line_1">
                                {({ field, form }) => (
                                    <FormControl
                                        isInvalid={form.errors.address_line_1 && form.touched.address_line_1}
                                    >
                                        <FormLabel htmlFor="address_line_1" color={textColor}>
                                            Address Line 1 *
                                        </FormLabel>
                                        <Input {...field} id="address_line_1" placeholder="Write your address here" />
                                        <FormErrorMessage>{form.errors.address_line_1}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="address_line_2">
                                {({ field }) => (
                                    <FormControl>
                                        <FormLabel htmlFor="address_line_2" color={textColor}>
                                            Address Line 2
                                        </FormLabel>
                                        <Input {...field} id="address_line_2" placeholder="Write your address here" />
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="state">
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.state && form.touched.state}>
                                        <FormLabel htmlFor="state" color={textColor}>
                                            State *
                                        </FormLabel>
                                        <Select
                                            {...field}
                                            id="state"
                                            placeholder="Choose your state"
                                            onChange={(e) => {
                                                const state = e.target.value;
                                                setFieldValue("state", state);
                                                setFieldValue("city", "");
                                                setSelectedState(state);
                                            }}
                                            isDisabled={!values.country || isStatesLoading}
                                        >
                                            {statesData?.map((state) =>(
                                                <option key={state.state_id} value={state.state_id}>
                                                    {state.state_name}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{form.errors.state}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Flex width="100%" alignContent="space-between" marginBottom={'30px'}>
                            <HStack align="start" spacing={5} width="100%">
                            <Field name="city">
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.city && form.touched.city} flex="1">
                                        <FormLabel htmlFor="city" color={textColor}>
                                            City *
                                        </FormLabel>
                                        <Select
                                            {...field}
                                            id="city"
                                            placeholder="Choose your city"
                                            isDisabled={!values.state || isCitiesLoading}
                                        >
                                            {citiesData?.map((city) => (
                                                <option key={city.id} value={city.id}>
                                                    {city.city}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="zip_or_postal_code">
                                        {({ field, form }) => (
                                            <FormControl
                                                isInvalid={form.errors.zip_or_postal_code && form.touched.zip_or_postal_code}
                                                flex="1"
                                            >
                                                <FormLabel htmlFor="zip_or_postal_code" color={textColor}>
                                                    ZIP or Postal Code *
                                                </FormLabel>
                                                <Input {...field} id="zip_or_postal_code" placeholder="12345" />
                                                <FormErrorMessage>{form.errors.zip_or_postal_code}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </HStack>
                            </Flex>
                            <Box pt={6}>
                                <Checkbox
                                    colorScheme="blue"
                                    isChecked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                >
                                    By filling this checkbox I accepted the Sales Contract.
                                </Checkbox>
                            </Box>
                        </VStack>
                        <Flex justifyContent={'end'} mt={10}>
                            <CButton
                                fontSize="base"
                                variant="brand"
                                fontWeight="500"
                                w="40%"
                                h="50"
                                type="submit"
                                bgColor="#203764"
                                textColor="#fff"
                                isDisabled={!isChecked}
                            >
                                Continue To Payment
                            </CButton>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default PaymentForm;