// Chakra imports
import {
  Flex,
  FormLabel,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import React from "react";

export default function Default(props) {
  const { id, label, extra, placeholder, type, mb, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  return (
    <Flex direction="column" >
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={id}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: "pointer" }}
      >
        {label}
        <Text fontSize="sm" fontWeight="400" ms="2px">
          {extra}
        </Text>
      </FormLabel>

      <Select
        placeholder="Select option"
        {...rest}
        type={type}
        id={id}
        fontWeight="500"
        variant="main"
        borderRadius="8px"
        _placeholder={{ fontWeight: "400", color: "brand.500" }}
        h="44px"
        maxh="44px"
      >
      
      </Select>
    </Flex>
  );
}
