import React from "react";
import {
  Box,
  Card,
  Flex,
  Text,
  Icon,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Skeleton,
  Badge,
} from "@chakra-ui/react";
import { Add, Edit } from "iconsax-react";

const isLoading = false;
const addresses = [
  { companyName: 'company name', address:  "{Full Company Address/Postal Code}", isDefault: true },
  { companyName: 'company name', address:  "{Full Company Address/Postal Code}", isDefault: false }
];

function BillingAddressInfo() {
  const handleDetail = (item) => {
    console.log(item);
  };

  return (
    <Card px={6} py={8} width={'100%'}>
      <Text fontSize={'2xl'} color={'primary.primary'} fontWeight={'medium'} mb={4}>Billing Information</Text>
      <Box
        overflowX="auto"
        mt={8}
        p={0}
        borderRadius={15}
        backgroundColor="white"
        boxShadow="sm"
      >
        <TableContainer>
          <Table size="sm">
            <Tbody alignItems='flex-start'>
              {isLoading ? (
                Array.from({ length: 4 }).map((_, index) => (
                  <Tr key={index} h={12}>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))
              ) : addresses.length > 0 ? (
                addresses.map((item) => (
                  <Tr
                    key={item.id}
                    _hover={{ bg: "#f7f7f7" }}
                    sx={{ borderBottom: "1px solid black" }}
                    onClick={() => handleDetail(item)}
                    cursor="pointer"
                  >
                    <Td
                      whiteSpace={{ base: 'initial', md: 'nowrap' }}
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      <Flex flexDirection={'column'} gap={2}>
                        <Text>{item.companyName || ""}</Text>
                        <Text>{item.address || ""}</Text>
                      </Flex>
                    </Td>
                    <Td 
                      width={{ base: '80px', md: 'auto' }}
                      >
                      {item.isDefault && (
                        <Badge
                          variant="subtle"
                          color={'info.info'}
                          borderRadius={'4px'}
                          backgroundColor={'info.onInfo'}
                          fontSize={'xs'}
                          textTransform="none"
                        >
                          Default Address
                        </Badge>
                      )}
                    </Td>
                    <Td textAlign={'end'} width={{ base: '30px', md: 'auto' }}>
                      <Button
                        variant="link"
                        onClick={() => handleDetail(item)}
                        href="#"
                      >
                        <Icon as={Edit} w="24px" h="24px" ml={1} />
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={3} textAlign="center" py={10}>
                    No Address Is Added
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Flex justifyContent={'flex-start'}>
        <Button variant="ghost" mt={4} size={'md'} colorScheme={'gray'} fontWeight={'normal'} rightIcon={<Add />}>Add Billing Address</Button>
      </Flex>
    </Card>
  );
}

export default BillingAddressInfo;
