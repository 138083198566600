import api from "../apiService";
import { useMutation } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

const editCustomer = async (body) => {
  const response = await api.post(API_URLS.billing_info, body);

  return response;
};

export default function useAddBillingInfo() {
  return useMutation({
    mutationFn: editCustomer,
  });
}
