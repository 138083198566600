import React from "react";
import { columns } from "../data/columns";
import { Button, Card, Stack } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";

const Columns = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateQuery = (column) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("column", column);
    setSearchParams(newParams);
  };

  return (
    <Card p="24px" my="24px" color="primary.primary">
      <Stack direction="row" justifyContent="space-between">
        {columns.map((column) => {
          const active = column === searchParams.get("column");
          return (
            <Button
              key={column}
              fontWeight="normal"
              variant="outline"
              border="2px"
              borderColor="transparent"
              onClick={() => {
                updateQuery(column);
              }}
              borderRadius={0}
              borderBottom={active && "2px solid"}
            >
              {column}
            </Button>
          );
        })}
      </Stack>
    </Card>
  );
};

export default Columns;
