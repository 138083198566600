import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  SignIn_service,
  SignUp_service,
  verifyOTP_service,
  verifyPasswordOTP_service,
  changePassword_service,
  forgetPassword_service,
  signupExpert_service,
  getUser_service,
  signInGoogle_service,
  signUpGoogle_service,
} from "services/userService";
import {
  addCompany_service,
  inviteExpert_service,
  getCompanyExperts_service,
  addProject_service,
  editProject_service,
  getCompanyProjects_service,
  getCompanyCustomers_service,
} from "services/companyService";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, setUser, logoutDone, userInfo } from "store/userActions";

const AuthContext = createContext();

export const AppContext = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [email, setEmail] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.auth);

  useEffect(() => {}, []);

  const login = async (username, password) => {
    try {
      const response = await SignIn_service(username, password);

      if (response.success) {
        var userInfo = await getuser(response.data.access_token);
        if (userInfo.user) {
          navigate("/admin/home");
        }
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const login_google = async (code) => {
    try {
      //const response = await signInGoogle_service(code);

      if (code) {
        var userInfo = await getuser(code);
        if (userInfo.user) {
          navigate("/admin/home");
        }
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  const register_google = async (code) => {
    try {
      //const response = await signUpGoogle_service(code);

      if (code) {
        var userInfo = await getuser(code);
        if (userInfo.user) {
          navigate("/onboarding/add-company");
        }
        return true;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const forgetPassword_sendEmail = async (username) => {
    try {
      const data = await forgetPassword_service(username);

      if (data.success) {
        dispatch(
          loginSuccess({
            isAuthenticated: false,
            token: "",
            username: username,
          })
        );
        navigate("/auth/otp?md=3droWssAp_wEn");

        return true;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const otp_verify = async (code, email) => {
    try {
      const response = await verifyOTP_service(code, email);

      if (response.success) {
        var userInfo = await getuser(response.data.token);
        if (userInfo.user) {
          navigate("/onboarding/add-company");
        }
        return true;
      }

      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const otp_verify_newPassword = async (code, email) => {
    try {
      const response = await verifyPasswordOTP_service(code, email);

      if (response.success) {
        //setIsAuthenticated(true);

        dispatch(
          setUser({
            isAuthenticated: false,
            username: email,
            otp: code,
          })
        );
        navigate("/auth/new_password");

        return true;
      }

      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const registerUserCompany = async (
    username,
    password,
    name,
    company_name
  ) => {
    try {
      const data = await SignUp_service(username, password, name, company_name);

      if (data.success) {
        dispatch(
          setUser({
            username: username,
            company: company_name,
          })
        );

        navigate("/auth/otp");
        return true;
      }
      return false;
    } catch (error) {
      // Handle login failure
      navigate("/auth/otp");
      return false;
    }
  };

  const register_expert = async (name, username, password, token) => {
    try {
      const data = await signupExpert_service(username, name, password, token);

      if (data.success) {
        dispatch(
          setUser({
            token: token,
            username: username,
          })
        );

        navigate("/auth/sign-in");
        return true;
      }
      return false;
    } catch (error) {
      // Handle login failure

      return false;
    }
  };

  const newPassword = async (password, confirm_password) => {
    try {
      var otp = userState.user.otp;
      var email = userState.user.username;

      const data = await new_password_service(
        email,
        otp,
        password,
        confirm_password
      );
      console.log(data);

      if (data.success) {
        navigate("/auth/sign-in");
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const logoutUser = () => {
    setIsAuthenticated(false);
    dispatch(logoutDone());
    navigate("/auth/sign-in");
    return false;
  };

  const onboarding = async (name, phone_number, address, logo, description) => {
    if (userState)
      try {
        const data = await addCompany_service(
          name,
          phone_number,
          address,
          logo,
          description,
          userState.user.token
        );

        if (data.success) {
          navigate("/onboarding/invite-experts");
          return true;
        }
        return false;
      } catch (error) {
        // Handle login failure
        console.log(error);
        return false;
      }
  };

  const inviteExpert = async (emails) => {
    if (userState)
      try {
        const data = await inviteExpert_service(emails, userState.user.token);

        if (data.success) {
          navigate("/admin/home");
          return true;
        }
        return false;
      } catch (error) {
        // Handle login failure
        console.log(error);
        return false;
      }
  };

  const getuser = async (token) => {
    try {
      const response = await getUser_service(token);

      if (response.success) {
        dispatch(userInfo(response.data, token));

        return response.data;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const getexperts = async () => {
    try {
      const response = await getCompanyExperts_service(userState.user.token);

      if (response.success) {
        return response.data;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const getProjects = async () => {
    try {
      const response = await getCompanyProjects_service(userState.user.token);

      if (response.success) {
        return response.data;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const getCustomers = async () => {
    try {
      const response = await getCompanyCustomers_service(userState.user.token);

      if (response.success) {
        return response.data;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const addProject = async (
    project_address,
    project_name,
    expert_id,
    description,
    customer_id,
    first_name,
    last_name,
    phone,
    email
  ) => {
    if (userState)
      try {
        const data = await addProject_service(
          project_address,
          project_name,
          expert_id,
          description,
          customer_id,
          first_name,
          last_name,
          phone,
          email,
          userState.user.token
        );

        if (data.success) {
          navigate("/onboarding/start-inspection");
          return true;
        }
        return false;
      } catch (error) {
        // Handle login failure
        console.log(error);
        return false;
      }
  };

  const editProject = async (
    project_id,
    project_address,
    project_name,
    expert_id,
    description,
    customer_id
  ) => {
    if (userState)
      try {
        const data = await editProject_service(
          project_id,
          project_address,
          project_name,
          expert_id,
          description,
          customer_id,
          userState.user.token
        );

        if (data.success) {
          getuser(userState.user.token);
          navigate("/admin/projects/project-details?projectId=" + project_id);
          return true;
        }
        return false;
      } catch (error) {
        // Handle login failure
        console.log(error);
        return false;
      }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logoutUser,
        registerUserCompany,
        otp_verify,
        forgetPassword_sendEmail,
        otp_verify_newPassword,
        newPassword,
        onboarding,
        register_expert,
        inviteExpert,
        getuser,
        login_google,
        register_google,
        getexperts,
        addProject,
        editProject,
        getProjects,
        getCustomers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
