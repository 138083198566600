// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Image,
  Badge,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import ProjectStatusBadge from "components/card/ProjectStatusBadge";
import { ArrowRight2, Location } from "iconsax-react";
import React from "react";
// Assets

export default function Project(props) {
  const { title, status, address, update, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} p="14px">
      <Flex align="center" direction={{ base: "column", md: "row" }}>
        {/* <Image h='80px' w='80px' src={image} borderRadius='8px' me='20px' /> */}
        <Flex
          direction={"column"}
          mt={{ base: "10px", md: "0" }}
          width="100%"
          gap={4}
        >
          <Box display="flex" width="100%" justifyContent="space-between">
            <Text color={textColorPrimary} fontWeight="900" fontSize="md">
              {title}
            </Text>
            <ProjectStatusBadge status={status} />
          </Box>
          <Flex justifyContent={"space-between"}>
            <Flex alignItems={"center"}>
              <Location variant="Bold" size={16} />
              <Text fontWeight="500" fontSize="sm" me="4px">
                Address :
              </Text>
              <Text
                fontWeight="500"
                color={textColorPrimary}
                fontSize="sm"
                me="4px"
                ml={2}
              >
                {address.length > 30 ? `${address.slice(0, 30)}...` : address}
              </Text>
            </Flex>
            <Flex alignItems={"center"} gap={1}>
              View <ArrowRight2 size={16} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
